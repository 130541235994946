



























import { CollectionDetail } from '@/models/collection.model'
import { collectionActions } from '@/store/modules/collection.store'
import { Component, Prop, Vue } from 'vue-property-decorator'
import SecondaryTitle from '@/components/SecondaryTitle.vue'

@Component({
    components: { SecondaryTitle },
})
export default class CollectionDetailComponent extends Vue {
    @Prop() collectionId!: number

    get collection(): CollectionDetail | undefined {
        return this.$store.state.collection.currentCollection
    }

    mounted(): void {
        this.$store.dispatch(collectionActions.retrieveCollection, this.collectionId)
    }

    destroyed(): void {
        this.$store.dispatch(collectionActions.removeCurrentCollection)
    }
}
