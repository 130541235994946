




















































import { SectionTask } from '@/models/section.model'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import vuetify from '@/plugins/vuetify'

@Component
export default class SectionDialog extends Vue {
    @Prop() private section?: SectionTask
    @Prop() isDialogOpen!: boolean

    confirmDelete = false

    private sectionForm = {
        valid: false,
        data: {
            name: '',
        },
        rules: {
            name: [
                (value: string) => !!value || 'Section name is required',
                (value: string) => value.length <= 50 || 'Max 50 characters',
            ],
        },
    }

    get form(): Vue & { resetValidation: () => void } {
        return this.$refs.form as Vue & { resetValidation: () => void }
    }

    get inputName(): Vue & { focus: () => void } {
        return this.$refs.name as Vue & { focus: () => void }
    }

    beforeMount(): void {
        if (this.section) this.populateForm(this.section.name)
    }

    @Watch('isDialogOpen')
    onIsDialogOpenChanges(value: boolean): void {
        if (value) {
            this.confirmDelete = false
            this.form.resetValidation()
            if (this.section) this.populateForm(this.section.name)
            else this.populateForm('')
            this.inputName.focus()
        }
    }

    populateForm(name: string): void {
        this.sectionForm.data.name = name
    }

    emitSubmitEvent(): void {
        if (!this.sectionForm.valid) return

        this.$emit('submit', this.sectionForm.data)
    }

    emitDeleteSection(): void {
        if (!this.confirmDelete) {
            this.confirmDelete = true
            return
        }

        this.$emit('delete')
    }

    emitCloseEvent(): void {
        this.$emit('close')
    }
}
