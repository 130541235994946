




































































import EmptyListDisplay from '@/components/EmptyListDisplay.vue'
import HalfDialog from '@/components/HalfDialog.vue'
import { EventModel } from '@/models/event.model'
import { dateFormat } from '@/pipes'
import EventItemCard from '@/views/components/event/EventItemCard.vue'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({ components: { EventItemCard, HalfDialog, EmptyListDisplay } })
export default class EventDayDialog extends Vue {
    @Prop({ required: true }) value!: boolean
    @Prop({ required: true }) date!: string
    @Prop({ required: true }) events!: EventModel[]

    isScrollingOnContent = false

    get scrollableElement(): Element {
        return this.$refs.dialogContent as Element
    }

    touchStartEvent() {
        // We detect if the touch-down is a scroll on the content
        this.isScrollingOnContent = this.scrollableElement.scrollTop > 0
    }

    handleTouchEvent(type: string): void {
        if (type === 'right' && this.$vuetify.breakpoint.width >= 400) this.$emit('input', false)
        else if (type === 'down' && this.$vuetify.breakpoint.width < 400) this.scrollDownEvent()
    }

    scrollDownEvent(): void {
        if (!this.isScrollingOnContent) this.$emit('input', false)
    }

    dateFormat(date: string, format: string): string {
        return dateFormat(date, format)
    }
}
