




























import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Tag, TagType } from '@/models/tag.model'
import { tagService } from '@/api'
import TagChip from '@/views/components/tag/TagChip.vue'

@Component({ components: { TagChip } })
export default class TagSearch extends Vue {
    @Prop({ required: true }) selectedTags!: Tag[]
    @Prop({ required: true }) type!: TagType
    @Prop({ default: false }) disabled!: boolean

    tagList: Tag[] = []
    search: string | null = null
    isLoading = false

    private searchTimer?: number = undefined

    @Watch('search')
    private onSearchChanges(value: string): void {
        clearTimeout(this.searchTimer)

        if (!value) {
            this.cleanTagList()
            return
        }

        this.isLoading = true
        this.searchTimer = setTimeout(() => this.searchTags(value), 200)
    }

    private searchTags(value: string): void {
        const excludedId = this.selectedTags.map(({ id }) => id).join(',')
        tagService
            .getTagList({
                type: this.type,
                search: value,
                size: 0,
                exclude_ids: excludedId || undefined,
            })
            .then((response: any) => (this.tagList = response.body.content))
            .catch((error: any) => console.error(error))
            .finally(() => (this.isLoading = false))
    }

    updateSelectedTags(selectedTags: Tag[]): void {
        this.$emit('update:selectedTags', [...selectedTags])
        this.cleanTagList()
    }

    cleanTagList(): void {
        this.tagList = []
        this.search = null
        this.isLoading = false
    }
}
