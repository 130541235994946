var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('TertiaryTitle',[_vm._v("User list")]),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.userList,"loading":_vm.loading},scopedSlots:_vm._u([{key:"item.username",fn:function(ref){
var item = ref.item;
var value = ref.value;
return [_vm._v(" "+_vm._s(value)+" "),(item.id === _vm.user.id)?[_c('v-icon',{attrs:{"small":"","title":"Account connected"}},[_vm._v("mdi-account-circle")])]:_vm._e()]}},{key:"item.firstName",fn:function(ref){
var value = ref.value;
return [_vm._v(_vm._s(value ? value : '-'))]}},{key:"item.lastName",fn:function(ref){
var value = ref.value;
return [_vm._v(_vm._s(value ? value : '-'))]}},{key:"item.dateJoined",fn:function(ref){
var value = ref.value;
return [(value)?[_vm._v(_vm._s(_vm.dateFormat(value, 'DD/MM/YYYY')))]:[_vm._v("-")]]}},{key:"item.lastLogin",fn:function(ref){
var value = ref.value;
return [(value)?[_vm._v(_vm._s(_vm.dateFormat(value, 'DD/MM/YYYY - HH:mm')))]:[_vm._v("-")]]}},{key:"item.isActive",fn:function(ref){
var value = ref.value;
return [(value)?[_c('v-icon',{attrs:{"color":"success"}},[_vm._v("mdi-check-circle")])]:[_c('v-icon',{attrs:{"color":"error"}},[_vm._v("mdi-close-circle")])]]}},{key:"item.isStaff",fn:function(ref){
var value = ref.value;
return [(value)?[_c('v-icon',{attrs:{"color":"info"}},[_vm._v("mdi-security")])]:_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":"","offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"plain":"","disabled":item.id === _vm.user.id}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{on:{"click":function($event){return _vm.resendActivationEmail(item.id)}}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-email-sync")]),_c('v-list-item-title',[_vm._v("Resend activation email")])],1),(item.isActive)?[_c('v-list-item',{on:{"click":function($event){return _vm.changeUserAccountState(item.id, false)}}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-account-remove")]),_c('v-list-item-title',[_vm._v("Deactivate user")])],1)]:[_c('v-list-item',{on:{"click":function($event){return _vm.changeUserAccountState(item.id, true)}}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-account-check")]),_c('v-list-item-title',[_vm._v("Activate user")])],1)],_c('ConfirmPasswordDialog',{on:{"password-confirmed":function($event){return _vm.deleteUser(item.id)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-list-item',_vm._g(_vm._b({},'v-list-item',attrs,false),on),[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-trash-can")]),_c('v-list-item-title',[_vm._v("Delete user")])],1)]}}],null,true)})],2)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }