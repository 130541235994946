





















































































































































































import EmptyListDisplay from '@/components/EmptyListDisplay.vue'
import FilterChip from '@/components/FilterChip.vue'
import ProgressWheel from '@/components/ProgressWheel.vue'
import { SectionPatch, SectionTask } from '@/models/section.model'
import { Task, TaskPatch, TaskPost } from '@/models/task.model'
import { projectActions } from '@/store/modules/project.store'
import TaskDialog from '@/views/components/task/TaskDialog.vue'
import TaskCard from '@/views/components/task/TaskCard.vue'
import SectionDialog from '@/views/project/project-detail/components/SectionDialog.vue'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { Preferences } from '@/models/preferences.model'
import { getDialogWidth } from '@/utils/dialog.utils'

@Component({
    methods: { getDialogWidth },
    components: {
        SectionDialog,
        TaskCard,
        TaskDialog,
        ProgressWheel,
        EmptyListDisplay,
        FilterChip,
    },
})
export default class ProjectSectionItem extends Vue {
    @Prop() section!: SectionTask
    @Prop() disabled!: boolean

    taskDialog = false
    sectionDialog = false
    displayCompletedTask = false

    get preferences(): Preferences {
        return this.$store.state.preferences.preferences
    }

    get completedTasks(): Task[] {
        return this.section.tasks.filter(({ completed }) => completed)
    }

    get uncompletedTasks(): Task[] {
        return this.section.tasks.filter(({ completed }) => !completed)
    }

    get completedTasksPercentage(): number {
        if (this.section.tasks.length === 0) return 0
        return Math.round((this.completedTasks.length / this.section.tasks.length) * 100)
    }

    get progressWheelSize(): 'x-small' | 'small' | 'medium' | 'large' {
        if (this.$vuetify.breakpoint.smAndDown) return 'x-small'
        else if (this.$vuetify.breakpoint.mdAndDown) return 'small'
        else if (this.$vuetify.breakpoint.lgAndDown) return 'medium'
        else return 'large'
    }

    updateSection({ name }: SectionPatch): void {
        this.sectionDialog = false
        this.$store.dispatch(projectActions.section.editSection, { id: this.section.id, name })
    }

    deleteSection(): void {
        this.sectionDialog = false
        this.$store.dispatch(projectActions.section.deleteSection, this.section.id)
    }

    createTask(task: TaskPost): void {
        this.taskDialog = false
        task.sectionId = this.section.id
        this.$store.dispatch(projectActions.task.addTask, task)
    }

    toggleTaskState(id: number, completed: boolean): void {
        this.$store.dispatch(projectActions.task.editTask, {
            id,
            data: { completed },
            sectionId: this.section.id,
        })
    }

    updateTask(id: number, data: TaskPatch): void {
        this.$store.dispatch(projectActions.task.editTask, { id, data, sectionId: this.section.id })
    }

    deleteTask(id: number): void {
        this.$store.dispatch(projectActions.task.deleteTask, { id, sectionId: this.section.id })
    }
}
