




























































































































import ProjectAvatar from '@/components/ProjectAvatar.vue'
import { EventModel, EventPostOrPatch } from '@/models/event.model'
import { Project } from '@/models/project.model'
import { dateFormat } from '@/pipes'
import { isPassed } from '@/utils/event.utils'
import EventDialog from '@/views/components/event/EventDialog.vue'
import moment from 'moment'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { getDialogWidth } from '@/utils/dialog.utils'

@Component({
    methods: { getDialogWidth },
    components: { EventDialog, ProjectAvatar },
})
export default class EventItemCard extends Vue {
    @Prop({ required: true }) event!: EventModel
    @Prop({ default: null }) project!: Project | null
    @Prop({ default: null }) color!: string | null
    @Prop({ default: true }) changePassedTextColor!: boolean
    @Prop({ default: false }) disabled!: boolean
    @Prop({ default: true }) clickable!: boolean
    @Prop({ default: false }) daySelected!: boolean
    @Prop({ default: false }) showIcon!: boolean
    @Prop({ default: true }) caret!: boolean
    @Prop({ default: true }) marginBottom!: boolean
    @Prop({ required: false }) relatedToDate?: string

    eventDialog = false
    displayDescription = false
    isDescriptionOverflowing = false
    descriptionElement?: HTMLElement

    get cardColor(): string | null {
        if (this.color) return this.color
        if (isPassed(this.event)) return 'null'

        return 'event'
    }

    mounted(): void {
        this.descriptionElement = this.$refs.description as HTMLElement
        if (this.descriptionElement && this.$vuetify.breakpoint.xsOnly)
            this.isDescriptionOverflowing =
                this.descriptionElement.scrollWidth > this.descriptionElement.clientWidth
    }

    onEventCardClick(): void {
        if (!this.clickable) {
            if (!this.event.description) return

            this.displayDescription = !this.displayDescription
        } else if (!this.disabled) {
            this.eventDialog = true
        }
    }

    emitUpdateEvent(data: EventPostOrPatch): void {
        this.eventDialog = false
        this.$emit('update', { id: this.event.id, data })
    }

    emitDeleteEvent(): void {
        this.eventDialog = false
        this.$emit('delete', this.event.id)
    }

    getTextColor(section: 'icon' | 'date' | 'name' | 'description'): string {
        const colorConfig = {
            icon: 'white--text',
            date: 'grey--text text--lighten-3',
            name: 'white--text',
            description: 'grey-text text--lighten-2',
        }

        let color: string
        if (isPassed(this.event) && this.changePassedTextColor) color = 'grey--text'
        else color = colorConfig[section]

        if (this.project?.archived) color += ' opacity-60'
        return color
    }

    isDateEqual(date1: string, date2: string): boolean {
        return moment(date1).isSame(date2, 'day')
    }

    dateFormat(date: string, format: string): string {
        return dateFormat(date, format)
    }
}
