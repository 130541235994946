































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { DailyTask } from '@/models/daily-task.model'
import CollectionChip from '@/components/CollectionChip.vue'
import SectionChip from '@/components/SectionChip.vue'
import ProjectChip from '@/components/ProjectChip.vue'
import TagGroup from '@/views/components/tag/TagGroup.vue'
import DailyTaskActionChip from '@/views/daily/components/DailyTaskActionChip.vue'

@Component({
    components: { DailyTaskActionChip, TagGroup, ProjectChip, SectionChip, CollectionChip },
})
export default class DailyTaskCard extends Vue {
    @Prop({ required: true }) dailyTask!: DailyTask

    get name(): string {
        if (this.dailyTask.task) return this.dailyTask.task.name
        else if (this.dailyTask.commonTask) return this.dailyTask.commonTask.name
        else return this.dailyTask.name as string // We know name is defined
    }

    emitDailyTaskToggle(): void {
        this.$emit('toggle')
    }
}
