






























import { Component, Vue } from 'vue-property-decorator'
import { TagType } from '@/models/tag.model'
import TagCard from '@/views/components/tag/TagCard.vue'
import TagDialog from '@/views/components/tag/TagDialog.vue'
import EmptyListDisplay from '@/components/EmptyListDisplay.vue'
import SettingsTagList from '@/views/settings/components/SettingsTagList.vue'
import TertiaryTitle from '@/components/TertiaryTitle.vue'

@Component({
    components: { TertiaryTitle, TagCard, TagDialog, EmptyListDisplay, SettingsTagList },
})
export default class SettingsTags extends Vue {
    tagTypes: TagType[] = ['project', 'task']
    tab: TagType = 'project'

    get tagList(): SettingsTagList {
        return this.$refs.tagList as SettingsTagList
    }

    openTagDialog(): void {
        this.tagList.openTagDialog()
    }
}
