
































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Form } from '@/models/common.model'
import { CollectionPost } from '@/models/collection.model'

@Component
export default class CollectionFormDialog extends Vue {
    @Prop({ required: true }) isDialogOpen!: boolean

    collectionForm: Form<CollectionPost> = {
        valid: false,
        data: {
            name: '',
            description: '',
            itemName: 'task',
        },
        rules: {
            name: [
                (value: string) => !!value || 'Collection name is required',
                (value: string) => value.length <= 50 || 'Max 50 characters',
            ],
            description: [
                (value: string) => !!value || 'Collection description is required',
                (value: string) => value.length <= 500 || 'Max 500 characters',
            ],
            itemName: [
                (value: string) => !!value || 'This field is required',
                (value: string) => value.length <= 15 || 'Max 15 characters',
            ],
        },
    }

    get form(): Vue & { resetValidation: () => void } {
        return this.$refs.form as Vue & { resetValidation: () => void }
    }

    get inputName(): Vue & { focus: () => void } {
        return this.$refs.name as Vue & { focus: () => void }
    }

    @Watch('isDialogOpen')
    private onIsDialogOpenChanges(value: boolean): void {
        if (!value) return

        this.form.resetValidation()
        this.inputName.focus()
        this.collectionForm.data = { name: '', description: '', itemName: 'task' }
    }

    private emitSubmitEvent(): void {
        if (!this.collectionForm.valid) return

        this.$emit('submit', this.collectionForm.data)
    }

    private emitCloseEvent(): void {
        this.$emit('close')
    }
}
