






























































import { Component, Prop, Vue } from 'vue-property-decorator'
import { Form } from '@/models/common.model'
import { ResetPasswordBody } from '@/models/auth.model'
import { authApi } from '@/api'
import { authService } from '@/services'

@Component
export default class ResetPassword extends Vue {
    @Prop({ default: '', required: true }) uidb64!: string
    @Prop({ default: '', required: true }) token!: string

    state: 'tokenValid' | 'tokenInvalid' | 'passwordChanged' | null = null

    form: Form<Pick<ResetPasswordBody, 'password' | 'confirmPassword'>> = {
        valid: false,
        pending: false,
        data: {
            password: '',
            confirmPassword: '',
        },
        rules: {
            password: [
                (value: string) => !!value || 'Password is required',
                (value: string) => value.length <= 64 || 'Max 64 characters',
            ],
            confirmPassword: [
                (value: string) => !!value || 'Password is required',
                (value: string) => value.length <= 64 || 'Max 64 characters',
            ],
        },
    }

    submitLoading = false

    showPassword = false
    showConfirmPassword = false

    passwordMatchError: string | null = null
    passwordValidationErrors: string[] = []
    private passwordValidationTimer?: number = undefined
    private passwordMatchTimer?: number = undefined

    created() {
        authApi
            .checkToken({ uidb64: this.uidb64, token: this.token })
            .then((response: any) => {
                this.state = response.body.valid ? 'tokenValid' : 'tokenInvalid'
            })
            .catch(() => (this.state = 'tokenInvalid'))
    }

    validatePasswordStrength(value: string): void {
        this.validatePasswordMatch()

        clearTimeout(this.passwordValidationTimer)
        if (value === '') {
            this.passwordValidationErrors = []
            return
        }

        this.form.pending = true
        this.passwordValidationTimer = setTimeout(() => this.testPasswordStrength(value), 500)
    }

    private testPasswordStrength(value: string): void {
        authApi
            .validatePassword({ password: value })
            .then((response: any) => (this.passwordValidationErrors = response.body.errors))
            .catch((error: any) => console.error(error))
            .finally(() => (this.form.pending = false))
    }

    validatePasswordMatch(): void {
        clearTimeout(this.passwordMatchTimer)
        const { password, confirmPassword } = this.form.data

        if (!password || !confirmPassword) {
            this.passwordMatchError = null
        }

        this.form.pending = true
        this.passwordMatchTimer = setTimeout(() => {
            this.passwordMatchError =
                password && confirmPassword && password !== confirmPassword
                    ? 'Passwords are not matching'
                    : null
            this.form.pending = false
        }, 300)
    }

    submit(): void {
        const data: ResetPasswordBody = {
            uidb64: this.uidb64,
            token: this.token,
            ...this.form.data,
        }

        authApi
            .resetPassword(data)
            .then(() => {
                this.state = 'passwordChanged'
                if (authService.isAuthenticated()) {
                    authService.removeToken()
                    authService.resetStore()
                }
            })
            .catch((error: any) => console.error(error))
    }
}
