




















import { Component, Vue } from 'vue-property-decorator'
import ConfirmPasswordDialog from '@/components/ConfirmPasswordDialog.vue'
import { userApi } from '@/api'
import { authService } from '@/services'
import TertiaryTitle from '@/components/TertiaryTitle.vue'

@Component({
    components: { TertiaryTitle, ConfirmPasswordDialog },
})
export default class ProfileAccount extends Vue {
    deleteAccount(): void {
        userApi
            .deleteAccount()
            .then(() => {
                authService.removeToken()
                authService.resetStore()
                this.$router.push({ name: 'login' })
            })
            .catch((error: any) => console.error(error))
    }
}
