













import { Project } from '@/models/project.model'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class ProjectAvatar extends Vue {
    @Prop({ required: true }) project!: Project
    @Prop({ default: false }) hover!: boolean
    @Prop({ default: false }) small!: boolean
}
