






























































































import EmptyListDisplay from '@/components/EmptyListDisplay.vue'
import FilterChip from '@/components/FilterChip.vue'
import { EventModel, EventPostOrPatch } from '@/models/event.model'
import { ProjectDetail } from '@/models/project.model'
import { projectActions } from '@/store/modules/project.store'
import { isPassed } from '@/utils/event.utils'
import EventDialog from '@/views/components/event/EventDialog.vue'
import EventItemCard from '@/views/components/event/EventItemCard.vue'
import { Component, Vue } from 'vue-property-decorator'
import { getDialogWidth } from '@/utils/dialog.utils'

@Component({
    methods: { getDialogWidth },
    components: { EventDialog, EventItemCard, EmptyListDisplay, FilterChip },
})
export default class ProjectEvent extends Vue {
    eventDialog = false
    displayPassedEvent = false

    get project(): ProjectDetail {
        return this.$store.state.project.currentProject
    }

    get comingEvents(): EventModel[] {
        return this.project.events.filter(event => !isPassed(event))
    }

    get passedEvents(): EventModel[] {
        return this.project.events.filter(event => isPassed(event)).reverse()
    }

    createEvent(event: EventPostOrPatch): void {
        this.eventDialog = false
        event.projectId = this.project.id
        this.$store.dispatch(projectActions.event.addEvent, event)
    }

    updateEvent(payload: { id: number; data: EventPostOrPatch }): void {
        this.$store.dispatch(projectActions.event.editEvent, payload)
    }

    deleteEvent(id: number): void {
        this.$store.dispatch(projectActions.event.deleteEvent, id)
    }
}
