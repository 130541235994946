export const apiRoutes = {
    login: 'auth/login/',
    logout: 'auth/logout/',
    activateUser: 'auth/activate/',
    resendActivationEmail: 'auth/resend-activation-email/',
    register: 'auth/register/',
    validatePassword: 'auth/validate-password/',
    resetPasswordRequest: 'auth/reset-password-request/',
    resetPassword: 'auth/reset-password/',
    confirmEmail: 'auth/confirm-email-change/',
    checkToken: 'auth/check-token/',
    checkPassword: 'auth/check-password/',
    project: 'project/',
    projectDetailed: 'project/detailed/',
    projectById: 'project/:projectId/',
    section: 'section/',
    sectionById: 'section/:sectionId/',
    task: 'task/',
    taskById: 'task/:taskId/',
    event: 'event/',
    eventById: 'event/:eventId/',
    collection: 'collection/',
    collectionDetailed: 'collection/detailed/',
    collectionById: 'collection/:collectionId/',
    dailyTask: 'daily-task/',
    dailyTaskSummary: 'daily-task/summary/',
    dailyTaskById: 'daily-task/:dailyTaskId/',
    tag: 'tag/',
    tagUnique: 'tag/is-name-unique/',
    tagById: 'tag/:id/',
    commonTask: 'common-task/',
    commonTaskUnique: 'common-task/is-name-unique/',
    commonTaskById: 'common-task/:id/',
    preferences: 'preferences/',
    user: 'user/',
    userById: 'user/:id/',
    userChangeAccountState: 'user/:id/account-state/',
    userResendActivationEmail: 'user/:id/resend-activation-email/',
    userConnected: 'user/me/',
    changePassword: 'user/me/change-password/',
    changeEmail: 'user/me/change-email/',
    deleteAccount: 'user/me/delete-account/',
    isUsernameUnique: 'user/is-username-unique/',
    isEmailUnique: 'user/is-email-unique/',
    feedback: 'feedback/',
    feedbackById: 'feedback/:id/',
}
