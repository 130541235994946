








































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import TagSearch from '@/views/components/tag/TagSearch.vue'
import TagChip from '@/views/components/tag/TagChip.vue'
import { Tag } from '@/models/tag.model'
import { Form } from '@/models/common.model'
import { ProjectPostOrPatch } from '@/models/project.model'

@Component({ components: { TagSearch, TagChip } })
export default class ProjectFormDialog extends Vue {
    @Prop() isDialogOpen!: boolean

    tagList: Tag[] = []
    projectForm: Form<ProjectPostOrPatch> = {
        valid: false,
        data: {
            name: '',
            description: '',
            tagIds: [],
        },
        rules: {
            name: [
                (value: string) => !!value || 'Project name is required',
                (value: string) => value.length <= 50 || 'Max 50 characters',
            ],
            description: [
                (value: string) => !!value || 'Project description is required',
                (value: string) => value.length <= 500 || 'Max 500 characters',
            ],
        },
    }

    get form(): Vue & { resetValidation: () => void } {
        return this.$refs.form as Vue & { resetValidation: () => void }
    }

    get inputName(): Vue & { focus: () => void } {
        return this.$refs.name as Vue & { focus: () => void }
    }

    @Watch('isDialogOpen')
    private onIsDialogOpenChanges(value: boolean): void {
        if (!value) return

        this.projectForm.data = { name: '', description: '', tagIds: [] }
        this.tagList = []
        this.form.resetValidation()
        this.inputName.focus()
    }

    @Watch('tagList')
    private onTagListChanges(value: Tag[]): void {
        this.projectForm.data.tagIds = value.map(({ id }) => id)
    }

    removeTag(id: number): void {
        this.tagList = this.tagList.filter(tag => tag.id !== id)
    }

    emitSubmitEvent(): void {
        if (!this.projectForm.valid) return

        this.$emit('submit', this.projectForm.data)
    }

    emitCloseEvent(): void {
        this.$emit('close')
    }
}
