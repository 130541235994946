import { render, staticRenderFns } from "./TaskCard.vue?vue&type=template&id=422497ba&scoped=true&"
import script from "./TaskCard.vue?vue&type=script&lang=ts&"
export * from "./TaskCard.vue?vue&type=script&lang=ts&"
import style0 from "./TaskCard.vue?vue&type=style&index=0&id=422497ba&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "422497ba",
  null
  
)

export default component.exports