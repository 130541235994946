



































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { config } from '@/config'
import { authService } from '@/services'
import { User } from '@/models/user.model'
import vuetify from '../../plugins/vuetify'

@Component
export default class TheHeader extends Vue {
    @Prop({ required: true }) displayNavbar!: boolean
    @Prop({ required: true }) headerMenu!: boolean

    appVersion = config.VERSION

    get user(): User {
        return this.$store.state.user.user
    }

    logout(): void {
        authService.logout().then(() => this.$router.push({ name: 'login' }))
    }
}
