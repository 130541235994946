var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"collection-general"},[_c('div',{staticClass:"collection-general__tasks"},[_c('div',{staticClass:"d-flex flex-column flex-sm-row flex-wrap column-gap-2 row-gap-1 mb-2"},[_c('h5',{staticClass:"text-h6 text-sm-h5 flex-grow-1"},[_vm._v("List of "+_vm._s(_vm.collection.itemName))]),_c('div',{staticClass:"d-flex justify-space-between align-center gap-2"},[(_vm.collection.tasks.length > 0)?_c('FilterChip',{staticClass:"flex-shrink-0",attrs:{"color":"green darken-2","icon":"mdi-trophy"},model:{value:(_vm.displayCompletedTask),callback:function ($$v) {_vm.displayCompletedTask=$$v},expression:"displayCompletedTask"}},[_vm._v(" Completed ")]):_vm._e(),_c('v-dialog',{attrs:{"width":_vm.getDialogWidth(),"fullscreen":_vm.$vuetify.breakpoint.smAndDown},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"disabled":_vm.collection.archived,"small":_vm.$vuetify.breakpoint.xsOnly && _vm.collection.itemName.length > 10,"block":_vm.$vuetify.breakpoint.xsOnly && _vm.collection.tasks.length === 0}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" "+_vm._s(_vm.collection.itemName)+" ")],1)]}}]),model:{value:(_vm.taskDialog),callback:function ($$v) {_vm.taskDialog=$$v},expression:"taskDialog"}},[_c('TaskDialog',{attrs:{"is-dialog-open":_vm.taskDialog,"item-name":_vm.collection.itemName},on:{"create":_vm.createTask,"close":function($event){_vm.taskDialog = false}}})],1)],1)]),(!_vm.displayCompletedTask)?[(_vm.uncompletedTasks.length > 0)?_vm._l((_vm.uncompletedTasks),function(task){return _c('TaskCard',{key:task.id,staticClass:"mb-2",attrs:{"task":task,"disabled":_vm.collection.archived,"item-name":_vm.collection.itemName},on:{"toggle-state":_vm.toggleTaskState,"update":_vm.updateTask,"delete":_vm.deleteTask}})}):(
                    _vm.collection.tasks.length > 0 &&
                    _vm.collection.tasks.length === _vm.completedTasks.length
                )?[_c('EmptyListDisplay',{staticClass:"empty-list-display",attrs:{"message":("You completed all the " + (_vm.collection.itemName) + " of this collection!")},scopedSlots:_vm._u([{key:"img",fn:function(){return [_c('img',{staticClass:"empty-list-display__img",attrs:{"src":require("../../../../assets/all_task_completed.svg"),"alt":"All tasks completed"}})]},proxy:true}],null,false,3760914584)})]:[_c('EmptyListDisplay',{staticClass:"empty-list-display",attrs:{"message":("This collection has no " + (_vm.collection.itemName) + ".")},scopedSlots:_vm._u([{key:"img",fn:function(){return [_c('img',{staticClass:"empty-list-display__img",attrs:{"src":require("../../../../assets/collection-no-tasks.svg"),"alt":"No tasks"}})]},proxy:true}],null,false,463178333)})]]:[(_vm.completedTasks.length > 0)?_vm._l((_vm.completedTasks),function(task){return _c('TaskCard',{key:task.id,staticClass:"mb-2",attrs:{"task":task,"disabled":_vm.collection.archived,"item-name":_vm.collection.itemName},on:{"toggle-state":_vm.toggleTaskState,"update":_vm.updateTask,"delete":_vm.deleteTask}})}):[_c('EmptyListDisplay',{staticClass:"empty-list-display",attrs:{"message":("You didn't complete any " + (_vm.collection.itemName) + " yet!")},scopedSlots:_vm._u([{key:"img",fn:function(){return [_c('img',{staticClass:"empty-list-display__img",attrs:{"src":require("../../../../assets/collection-no-tasks.svg"),"alt":"No tasks"}})]},proxy:true}])})]]],2),_c('div',{staticClass:"collection-general__description"},[_c('div',{staticClass:"d-flex justify-center mb-2"},[_c('ProgressWheel',{attrs:{"size":_vm.progressWheelSize,"mode":_vm.preferences.progressWheelMode,"value":_vm.completedTasks.length,"max":_vm.collection.tasks.length,"color":"collection lighten-2"}})],1),_c('h5',{staticClass:"text-h6 text-sm-h5 mb-1 mb-lg-2"},[_vm._v("Description")]),_c('v-card',{staticClass:"rounded-lg"},[_c('v-card-text',[_c('div',{ref:"description",staticClass:"collection-general__description__content text-body-2",class:{
                        'display-description': _vm.displayDescription,
                        'cursor-pointer': _vm.isDescriptionOverflowing,
                    },on:{"click":function($event){_vm.displayDescription = !_vm.displayDescription}}},[_vm._v(" "+_vm._s(_vm.collection.description)+" ")]),_c('div',{staticClass:"d-flex justify-end align-center mt-1",attrs:{"title":"Created on"}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-clock")]),_c('span',{staticClass:"font-italic ml-1"},[_vm._v(_vm._s(_vm.createdDate))])],1)])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }