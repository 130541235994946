export const TAG_COLOR_OPTIONS: string[] = [
    '#37474F',
    '#607D8B',
    '#880E4F',
    '#9C27B0',
    '#673AB7',
    '#3F51B5',
    '#0D47A1',
    '#2962FF',
    '#2196F3',
    '#00BCD4',
    '#009688',
    '#4CAF50',
    '#8BC34A',
    '#9E9D24',
    '#FFB300',
    '#FB8C00',
    '#FF5722',
    '#E53935',
    '#B71C1C',
    '#794948',
]
