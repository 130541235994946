






































import { Component, Vue } from 'vue-property-decorator'
import { User } from '@/models/user.model'
import MainTitle from '@/components/MainTitle.vue'

@Component({
    components: { MainTitle },
})
export default class ProfileComponent extends Vue {
    get user(): User | undefined {
        return this.$store.state.user.user
    }
}
