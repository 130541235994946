


























































import { eventService } from '@/api/event.api'
import EmptyListDisplay from '@/components/EmptyListDisplay.vue'
import { EventModel, EventPostOrPatch } from '@/models/event.model'
import { isEventRelatedToDate, sortEvents } from '@/utils/event.utils'
import EventDialog from '@/views/components/event/EventDialog.vue'
import EventItemCard from '@/views/components/event/EventItemCard.vue'
import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import { getDialogWidth } from '@/utils/dialog.utils'

@Component({
    methods: { getDialogWidth },
    components: { EventItemCard, EventDialog, EmptyListDisplay },
})
export default class DailyUpdateEvent extends Vue {
    @Prop({ required: true }) private date!: string

    eventList: EventModel[] = []
    eventDialog = false

    created(): void {
        this.retrieveEventList()
    }

    @Emit('daily-event-count')
    private emitDailyEventCount(): number {
        return this.eventList.length
    }

    private retrieveEventList(): void {
        eventService.getEvents({ date: this.date }).then(
            (response: any) => {
                this.eventList = response.body
                this.sortEventList()
                this.emitDailyEventCount()
            },
            (error: any) => console.error(error)
        )
    }

    private sortEventList(): void {
        this.eventList.sort((event1, event2) => sortEvents(event1, event2))
    }

    createEvent(event: EventPostOrPatch): void {
        eventService.createEvent(event, { extended: true }).then(
            (response: any) => {
                const event = response.body
                if (isEventRelatedToDate(event, this.date)) {
                    this.eventList.push(response.body)
                    this.sortEventList()
                    this.emitDailyEventCount()
                }
                this.eventDialog = false
            },
            (error: any) => console.error(error)
        )
    }

    updateEvent({ id, data }: { id: number; data: EventPostOrPatch }): void {
        eventService.updateEventById(id, data, { extended: true }).then(
            (response: any) => {
                const eventIndex = this.eventList.findIndex(e => e.id === id)
                if (eventIndex === -1) return

                const event = response.body
                if (isEventRelatedToDate(event, this.date)) {
                    this.eventList.splice(eventIndex, 1, response.body)
                    this.sortEventList()
                } else {
                    this.eventList.splice(eventIndex, 1)
                    this.emitDailyEventCount()
                }

                this.eventDialog = false
            },
            (error: any) => console.error(error)
        )
    }

    deleteEvent(id: number): void {
        eventService.deleteEventById(id).then(
            () => {
                const eventIndex = this.eventList.findIndex(event => event.id === id)
                if (eventIndex === -1) return

                this.eventList.splice(eventIndex, 1)
                this.eventDialog = false
                this.emitDailyEventCount()
            },
            (error: any) => console.error(error)
        )
    }
}
