















































































































































import { eventService } from '@/api/event.api'
import { EventModel, EventPostOrPatch } from '@/models/event.model'
import { dateFormat } from '@/pipes'
import { isEventRelatedToDate, sortEvents } from '@/utils/event.utils'
import EventDayDialog from '@/views/components/event/EventDayDialog.vue'
import EventDialog from '@/views/components/event/EventDialog.vue'
import EventTooltip from '@/views/components/event/EventTooltip.vue'
import moment from 'moment'
import { Component, Vue } from 'vue-property-decorator'
import MainTitle from '@/components/MainTitle.vue'
import { getDialogWidth } from '@/utils/dialog.utils'

@Component({
    methods: { getDialogWidth },
    components: { MainTitle, EventDialog, EventTooltip, EventDayDialog },
})
export default class Agenda extends Vue {
    events: EventModel[] = [] // TODO : think of using Set

    value = moment().format('YYYY-MM-DD')
    weekdays = [1, 2, 3, 4, 5, 6, 0]

    eventDialog = false
    eventToUpdate: EventModel | null = null
    startDatePlaceholder: string | null = null

    eventTooltip = false
    eventTooltipKey = 0
    eventSelected: EventModel | null = null
    eventTooltipElement: EventTarget | null = null

    eventDayDialog = false
    eventDayDialogDate: string | null = null
    eventDayDialogEvents: EventModel[] = []

    doubleClickTimer?: number = undefined

    get monthSelected(): string {
        return moment(this.value).format('MMMM YYYY')
    }

    get isCurrentMonthSelected(): boolean {
        return moment(this.value).isSame(moment(), 'month')
    }

    get calendar(): Vue & { next: () => void; prev: () => void } {
        return this.$refs.calendar as Vue & { next: () => void; prev: () => void }
    }

    created(): void {
        this.retrieveEvents()
    }

    retrieveEvents(): void {
        this.events = []
        const month = moment(this.value).month() + 1 // Month count start at 0
        const year = moment(this.value).year()
        eventService.getEvents({ month, year }).then(
            (response: any) => (this.events = response.body),
            (error: any) => console.error(error)
        )
    }

    openEventDialog(options: { event?: EventModel; startDatePlaceholder?: string } = {}): void {
        const { event, startDatePlaceholder } = options
        this.eventToUpdate = event ?? null
        this.startDatePlaceholder = startDatePlaceholder ?? null
        this.eventDialog = true
    }

    createEvent(event: EventPostOrPatch): void {
        this.eventSelected = null
        eventService.createEvent(event, { extended: true }).then(
            (response: any) => {
                this.events.push(response.body)
                this.eventDialog = false
                if (this.eventDayDialog) this.setDayDialogEventList()
            },
            (error: any) => console.error(error)
        )
    }

    updateEvent({ id, data }: { id: number; data: EventPostOrPatch }): void {
        eventService.updateEventById(id, data, { extended: true }).then(
            (response: any) => {
                const eventIndex = this.events.findIndex(e => e.id === id)
                if (eventIndex === -1) return

                const updatedEvent = response.body
                this.events.splice(eventIndex, 1, updatedEvent)
                if (this.eventSelected?.id === updatedEvent.id) this.eventSelected = updatedEvent
                if (this.eventDayDialog) this.setDayDialogEventList()
                else this.eventDialog = false
            },
            (error: any) => console.error(error)
        )
    }

    deleteEvent(id: number): void {
        eventService.deleteEventById(id).then(
            () => {
                const eventIndex = this.events.findIndex(event => event.id === id)
                if (eventIndex === -1) return

                this.events.splice(eventIndex, 1)

                if (this.eventDayDialog) this.setDayDialogEventList({ sort: false })
                else this.eventDialog = false
            },
            (error: any) => console.error(error)
        )
    }

    // Tooltip removed temporally
    openEventTooltip($event: { nativeEvent: MouseEvent; event: EventModel }): void {
        const { nativeEvent, event } = $event

        nativeEvent.stopImmediatePropagation()
        this.eventTooltipKey += 1 // Hack to re-render v-menu component
        this.eventSelected = event
        this.eventTooltipElement = nativeEvent.target
        if (!this.eventTooltip) this.eventTooltip = true
    }

    handleClickOnDay($event: { date: string }): void {
        const { date } = $event
        const delay = 200

        if (this.doubleClickTimer) {
            clearTimeout(this.doubleClickTimer)
            this.doubleClickTimer = undefined
            this.openEventDialog({ startDatePlaceholder: date })
        } else {
            this.doubleClickTimer = setTimeout(() => {
                this.openDayDialog(date)
                this.doubleClickTimer = undefined
            }, delay)
        }
    }

    openDayDialog(date: string): void {
        this.eventDayDialog = true
        this.eventDayDialogDate = date
        this.setDayDialogEventList()
    }

    // TODO : See if this can be optimized
    private setDayDialogEventList(options: { sort: boolean } = { sort: true }): void {
        if (!this.eventDayDialogDate) return

        this.eventDayDialogEvents = this.events.filter(
            event => isEventRelatedToDate(event, <string>this.eventDayDialogDate) // We have tested that date is not null before
        )
        if (options.sort)
            this.eventDayDialogEvents.sort((event1, event2) => sortEvents(event1, event2))
    }

    setCalendarToNow(): void {
        this.value = moment().format('YYYY-MM-DD')
        this.retrieveEvents()
    }

    previousMonth(): void {
        this.calendar.prev()
        this.retrieveEvents()
    }

    nextMonth(): void {
        this.calendar.next()
        this.retrieveEvents()
    }

    dateFormat(date: string, format: string): string {
        return dateFormat(date, format)
    }
}
