

























































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { DailyTask, DailyTaskPatch, DailyUpdateTaskTab } from '@/models/daily-task.model'
import CollectionChip from '@/components/CollectionChip.vue'
import SectionChip from '@/components/SectionChip.vue'
import ProjectChip from '@/components/ProjectChip.vue'
import TagGroup from '@/views/components/tag/TagGroup.vue'
import DailyTaskActionChip from '@/views/daily/components/DailyTaskActionChip.vue'
import DailyTaskForm from '@/views/daily/components/DailyTaskForm.vue'

@Component({
    components: {
        DailyTaskForm,
        DailyTaskActionChip,
        ProjectChip,
        SectionChip,
        CollectionChip,
        TagGroup,
    },
})
export default class DailyTaskFormCard extends Vue {
    @Prop({ required: true }) dailyTask!: DailyTask
    @Prop({ required: true }) editMode!: boolean

    dailyTaskUpdateTabEnum = DailyUpdateTaskTab

    get name(): string {
        if (this.dailyTask.task) return this.dailyTask.task.name
        else if (this.dailyTask.commonTask) return this.dailyTask.commonTask.name
        else return this.dailyTask.name as string // We know name is defined
    }

    showEditMode(): void {
        this.$emit('show-edit-mode')
    }

    hideEditMode(): void {
        this.$emit('hide-edit-mode')
    }

    updateDailyTask(data: DailyTaskPatch): void {
        this.$emit('update', data)
    }

    deleteDailyTask(): void {
        this.$emit('delete')
    }

    select(tab: DailyUpdateTaskTab, id: number, sectionId?: number): void {
        this.$emit('select', { tab, id, sectionId })
    }
}
