

















































































import { Component, Vue } from 'vue-property-decorator'
import { Form } from '@/models/common.model'
import { authApi, userApi } from '@/api'
import { RegisterPost } from '@/models/auth.model'

@Component
export default class Register extends Vue {
    form: Form<RegisterPost> = {
        valid: false,
        pending: false,
        data: {
            username: '',
            email: '',
            password: '',
            confirmPassword: '',
        },
        rules: {
            username: [
                (value: string) => !!value || 'Username is required',
                (value: string) => value.length <= 100 || 'Max 100 characters',
            ],
            email: [
                (value: string) => !!value || 'Email is required',
                (value: string) => value.length <= 100 || 'Max 100 characters',
                (value: string) =>
                    /^[\w-\.]+@([\w-]+\.)+[\w-]{2,8}$/.test(value) || 'Invalid e-mail address',
            ],
            password: [
                (value: string) => !!value || 'Password is required',
                (value: string) => value.length <= 64 || 'Max 64 characters',
            ],
            confirmPassword: [
                (value: string) => !!value || 'Password is required',
                (value: string) => value.length <= 64 || 'Max 64 characters',
            ],
        },
    }

    submitLoading = false
    userCreated = false

    showPassword = false
    showConfirmPassword = false

    usernameUniqueError: string | null = null
    emailUniqueError: string | null = null
    passwordMatchError: string | null = null
    passwordValidationErrors: string[] = []

    private usernameValidationTimer?: number = undefined
    private emailValidationTimer?: number = undefined
    private passwordValidationTimer?: number = undefined
    private passwordMatchTimer?: number = undefined

    validateUsername(value: string): void {
        clearTimeout(this.usernameValidationTimer)
        if (value === '') {
            this.usernameUniqueError = null
            return
        }

        this.form.pending = true
        this.usernameValidationTimer = setTimeout(() => this.isUsernameUnique(value), 300)
    }

    private isUsernameUnique(value: string): void {
        userApi
            .isUsernameUnique({ username: value })
            .then((response: any) => {
                this.usernameUniqueError = !response.body.unique
                    ? 'This username is already used'
                    : null
            })
            .catch((error: any) => console.error(error))
            .finally(() => (this.form.pending = false))
    }

    validateEmail(value: string): void {
        clearTimeout(this.emailValidationTimer)
        if (value === '') {
            this.emailUniqueError = null
            return
        }

        this.form.pending = true
        this.emailValidationTimer = setTimeout(() => this.isEmailUnique(value), 300)
    }

    private isEmailUnique(value: string): void {
        userApi
            .isEmailUnique({ email: value })
            .then((response: any) => {
                this.emailUniqueError = !response.body.unique ? 'This email is already used' : null
            })
            .catch((error: any) => console.error(error))
            .finally(() => (this.form.pending = false))
    }

    validatePasswordStrength(value: string): void {
        this.validatePasswordMatch()

        clearTimeout(this.passwordValidationTimer)
        if (value === '') {
            this.passwordValidationErrors = []
            return
        }

        this.form.pending = true
        this.passwordValidationTimer = setTimeout(() => this.testPasswordStrength(value), 500)
    }

    private testPasswordStrength(value: string): void {
        authApi
            .validatePassword({ password: value })
            .then((response: any) => (this.passwordValidationErrors = response.body.errors))
            .catch((error: any) => console.error(error))
            .finally(() => (this.form.pending = false))
    }

    validatePasswordMatch(): void {
        clearTimeout(this.passwordMatchTimer)
        const { password, confirmPassword } = this.form.data

        if (!password || !confirmPassword) {
            this.passwordMatchError = null
        }

        this.form.pending = true
        this.passwordMatchTimer = setTimeout(() => {
            this.passwordMatchError =
                password && confirmPassword && password !== confirmPassword
                    ? 'Passwords are not matching'
                    : null
            this.form.pending = false
        }, 300)
    }

    registerUser(): void {
        if (!this.form.valid || this.form.pending) return

        this.submitLoading = true
        authApi
            .register(this.form.data)
            .then(() => (this.userCreated = true))
            .catch((error: any) => console.error(error))
            .finally(() => (this.submitLoading = false))
    }
}
