







































import { dateFormat } from '@/pipes'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { DailySummary } from 'src/models/daily-summary.model'
import TaskCounter from '@/components/TaskCounter.vue'

@Component({ components: { TaskCounter } })
export default class DailySummaryCardComponent extends Vue {
    @Prop({ required: true }) dailySummary!: DailySummary

    // todo : Set colorArray
    get colorOfTaskCompleted(): string {
        const colorArray = [
            '#163317',
            'green darken-4',
            'green darken-3',
            'green darken-2',
            'green',
        ]
        const index =
            Math.trunc(
                (this.dailySummary.totalTaskCompleted * colorArray.length) /
                    this.dailySummary.totalTask
            ) - 1
        return colorArray[index]
    }

    get backgroundColor(): string | null {
        if (!this.dailySummary.totalTask && !this.dailySummary.totalEvent) return '#151515'
        else if (!this.dailySummary.totalTaskCompleted) return null

        return this.colorOfTaskCompleted
    }

    openDailyDetailDialog(): void {
        this.$emit('open-daily-detail')
    }

    dateFormat(date: string, format: string): string {
        return dateFormat(date, format)
    }
}
