import { render, staticRenderFns } from "./ProjectDetail.vue?vue&type=template&id=d7ef5662&scoped=true&"
import script from "./ProjectDetail.vue?vue&type=script&lang=ts&"
export * from "./ProjectDetail.vue?vue&type=script&lang=ts&"
import style0 from "./ProjectDetail.vue?vue&type=style&index=0&id=d7ef5662&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d7ef5662",
  null
  
)

export default component.exports