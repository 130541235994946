var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"daily-task-form-card rounded-lg"},[(!_vm.editMode && _vm.dailyTask)?[_c('div',{staticClass:"daily-task-form-card__content"},[_c('div',{staticClass:"daily-task-form-card__actions"},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"daily-task-form-card__actions__btn",attrs:{"small":"","plain":"","color":"white"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-dots-vertical")])],1)]}}],null,false,2185027647)},[_c('v-list',{attrs:{"dense":""}},[(!_vm.dailyTask.task && !_vm.dailyTask.commonTask)?_c('v-list-item',{on:{"click":function($event){return _vm.showEditMode()}}},[_c('v-icon',{attrs:{"small":"","left":"","color":"accent"}},[_vm._v("mdi-pencil")]),_c('v-list-item-title',[_vm._v("Edit")])],1):_vm._e(),_c('v-list-item',{on:{"click":function($event){return _vm.deleteDailyTask()}}},[_c('v-icon',{attrs:{"small":"","left":"","color":"error"}},[_vm._v("mdi-trash-can")]),_c('v-list-item-title',[_vm._v("Delete")])],1)],1)],1)],1),_c('div',{staticClass:"daily-task-form-card__content__header"},[_c('DailyTaskActionChip',{staticClass:"flex-shrink-0",attrs:{"action":_vm.dailyTask.action,"editable":true},on:{"update":function($event){return _vm.updateDailyTask({ action: $event })}}}),_c('h4',{staticClass:"text-body-2 text-sm-body-1 font-weight-medium",attrs:{"title":_vm.name}},[_vm._v(" "+_vm._s(_vm.name)+" ")])],1),(
                    _vm.dailyTask.task ||
                    (_vm.dailyTask.commonTask && _vm.dailyTask.commonTask.tags.length > 0) ||
                    _vm.dailyTask.tags.length > 0
                )?[_c('div',{staticClass:"daily-task-form-card__content__footer"},[(_vm.dailyTask.task)?[(_vm.dailyTask.task.project)?_c('ProjectChip',{staticClass:"daily-task-form-card__content__footer__chip",attrs:{"project":_vm.dailyTask.task.project,"small":true,"navigate-to-detail":false},on:{"click":function($event){return _vm.select(
                                    _vm.dailyTaskUpdateTabEnum.Project,
                                    _vm.dailyTask.task.project.id
                                )}}}):_vm._e(),(_vm.dailyTask.task.section)?_c('SectionChip',{staticClass:"daily-task-form-card__content__footer__chip",attrs:{"section":_vm.dailyTask.task.section,"small":true,"navigate-to-detail":false},on:{"click":function($event){return _vm.select(
                                    _vm.dailyTaskUpdateTabEnum.Project,
                                    _vm.dailyTask.task.section.project.id,
                                    _vm.dailyTask.task.section.id
                                )}}}):_vm._e(),(_vm.dailyTask.task.collection)?_c('CollectionChip',{staticClass:"daily-task-form-card__content__footer__chip",attrs:{"collection":_vm.dailyTask.task.collection,"small":true,"navigate-to-detail":false},on:{"click":function($event){return _vm.select(
                                    _vm.dailyTaskUpdateTabEnum.Collection,
                                    _vm.dailyTask.task.collection.id
                                )}}}):_vm._e()]:_vm._e(),(_vm.dailyTask.tags.length > 0)?[_c('TagGroup',{attrs:{"tag-list":_vm.dailyTask.tags,"max-tag":2}})]:_vm._e(),(_vm.dailyTask.task && _vm.dailyTask.task.tags.length > 0)?[_c('TagGroup',{attrs:{"tag-list":_vm.dailyTask.task.tags,"max-tag":2}})]:_vm._e(),(_vm.dailyTask.commonTask && _vm.dailyTask.commonTask.tags.length > 0)?[_c('TagGroup',{attrs:{"tag-list":_vm.dailyTask.commonTask.tags,"max-tag":2}})]:_vm._e()],2)]:_vm._e()],2)]:[_c('DailyTaskForm',{staticClass:"flex-grow-1",attrs:{"daily-task":_vm.dailyTask},on:{"submit":function($event){return _vm.updateDailyTask($event)},"close":function($event){return _vm.hideEditMode()}}})]],2)}
var staticRenderFns = []

export { render, staticRenderFns }