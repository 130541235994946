






































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Tag, TagForm, TagType } from '@/models/tag.model'
import TagDialog from '@/views/components/tag/TagDialog.vue'
import EmptyListDisplay from '@/components/EmptyListDisplay.vue'
import TagCard from '@/views/components/tag/TagCard.vue'
import { tagService } from '@/api'
import { getDialogWidth } from '@/utils/dialog.utils'

@Component({
    methods: { getDialogWidth },
    components: { TagDialog, TagCard, EmptyListDisplay },
})
export default class SettingsTagList extends Vue {
    @Prop({ required: true }) type!: TagType

    tagList: Tag[] = []
    tagDialog = false

    created(): void {
        this.fetchTagList()
    }

    @Watch('type')
    private onTypeChange(): void {
        this.fetchTagList()
    }

    private fetchTagList(): void {
        tagService
            .getTagList({ size: 0, type: this.type })
            .then((response: any) => {
                this.tagList = response.body.content
            })
            .catch((error: any) => console.error(error))
    }

    openTagDialog(): void {
        this.tagDialog = true
    }

    createTag(data: TagForm): void {
        tagService
            .createTag(data)
            .then((response: any) => {
                this.tagList.push(response.body)
                this.tagDialog = false
            })
            .catch((error: any) => console.error(error))
    }

    updateTag({ id, data }: { id: number; data: TagForm }): void {
        tagService
            .updateTag(id, data)
            .then((response: any) => {
                const index = this.tagList.findIndex(tag => tag.id === id)
                if (index !== -1) this.tagList.splice(index, 1, response.body)
            })
            .catch((error: any) => console.error(error))
    }

    deleteTag(id: number): void {
        tagService
            .deleteTag(id)
            .then(() => {
                const index = this.tagList.findIndex(tag => tag.id === id)
                if (index !== -1) this.tagList.splice(index, 1)
            })
            .catch((error: any) => console.error(error))
    }
}
