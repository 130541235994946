













































import { Component, Vue } from 'vue-property-decorator'
import { feedbackApi } from '@/api'
import { Feedback } from '@/models/feedback.model'
import { DataTableHeader } from 'vuetify'
import { dateFormat } from '@/pipes'
import ConfirmDialog from '@/components/ConfirmDialog.vue'
import TertiaryTitle from '@/components/TertiaryTitle.vue'

@Component({
    components: { TertiaryTitle, ConfirmDialog },
})
export default class AdministrationFeedback extends Vue {
    feedbackList: Feedback[] = []
    headers: DataTableHeader[] = [
        { text: 'Title', value: 'title' },
        { text: 'User', value: 'user', width: 150, align: 'center' },
        { text: 'Date', value: 'date', width: 110, align: 'center' },
        { text: '', value: 'actions', width: 105, sortable: false },
    ]

    created(): void {
        feedbackApi
            .getFeedback()
            .then((response: any) => {
                this.feedbackList = response.body.content
            })
            .catch((error: any) => console.error(error))
    }

    feedbackExpanded(event: { item: Feedback; value: boolean }): void {
        const { item, value } = event
        if (item.isRead || !value) return

        // We set first the isRead to true, and then we call the api.
        // This prevents a blinking effect
        const feedback = this.feedbackList.find(({ id }) => item.id === id)
        if (feedback) feedback.isRead = true

        feedbackApi
            .setFeedbackReadProperty(item.id, true)
            .catch((error: any) => console.error(error))
    }

    setFeedbackAsUnread(id: number): void {
        feedbackApi
            .setFeedbackReadProperty(id, false)
            .then(() => {
                const feedback = this.feedbackList.find(item => item.id === id)
                if (feedback) feedback.isRead = false
            })
            .catch((error: any) => console.error(error))
    }

    deleteFeedback(id: number): void {
        feedbackApi.deleteFeedback(id).then(() => {
            const index = this.feedbackList.findIndex(feedback => feedback.id === id)
            if (index !== -1) this.feedbackList.splice(index, 1)
        })
    }

    getItemClass(item: Feedback): string {
        return item.isRead
            ? 'grey--text text--lighten-2 font-weight-light'
            : 'white--text font-weight-bold'
    }

    dateFormat(date: string, format: string): string {
        return dateFormat(date, format)
    }
}
