













































import { Component, Vue } from 'vue-property-decorator'
import { commonTaskService } from '@/api'
import { CommonTask, CommonTaskForm } from '@/models/common-task.model'
import CommonTaskCard from '@/views/components/common-task/CommonTaskCard.vue'
import CommonTaskDialog from '@/views/components/common-task/CommonTaskDialog.vue'
import EmptyListDisplay from '@/components/EmptyListDisplay.vue'
import TertiaryTitle from '@/components/TertiaryTitle.vue'

@Component({ components: { TertiaryTitle, CommonTaskCard, CommonTaskDialog, EmptyListDisplay } })
export default class SettingsCommonTasks extends Vue {
    commonTaskList: CommonTask[] = []

    commonTaskDialog = false

    created(): void {
        this.fetchCommonTaskList()
    }

    private fetchCommonTaskList(): void {
        commonTaskService
            .getCommonTaskList({ size: 0 })
            .then((response: any) => {
                this.commonTaskList = response.body.content
            })
            .catch((error: any) => console.error(error))
    }

    createCommonTask(data: CommonTaskForm): void {
        commonTaskService
            .createCommonTask(data)
            .then((response: any) => {
                this.commonTaskList.push(response.body)
                this.commonTaskDialog = false
            })
            .catch((error: any) => console.error(error))
    }

    updateCommonTask({ id, data }: { id: number; data: CommonTaskForm }): void {
        commonTaskService
            .updateCommonTask(id, data)
            .then((response: any) => {
                const index = this.commonTaskList.findIndex(commonTask => commonTask.id === id)
                if (index !== -1) this.commonTaskList.splice(index, 1, response.body)
            })
            .catch((error: any) => console.error(error))
    }

    deleteCommonTask(id: number): void {
        commonTaskService
            .deleteCommonTask(id)
            .then(() => {
                const index = this.commonTaskList.findIndex(commonTask => commonTask.id === id)
                if (index !== -1) this.commonTaskList.splice(index, 1)
            })
            .catch((error: any) => console.error(error))
    }
}
