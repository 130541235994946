
























































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Tag, TagForm } from '@/models/tag.model'
import { Form } from '@/models/common.model'
import { TAG_COLOR_OPTIONS } from '@/utils/constants'
import { IsTagNameUniqueParams } from '@/api/tag.api'
import { tagService } from '@/api'

@Component
export default class TagDialog extends Vue {
    @Prop({ required: false }) tag?: Tag
    @Prop({ required: true }) isDialogOpen!: boolean
    @Prop({ required: true, validator: value => value === 'project' || value === 'task' })
    type!: 'project' | 'task'

    colorOptions = TAG_COLOR_OPTIONS

    confirmDelete = false
    colorPicker = false

    nameUniqueError: string | null = null
    inputNameLoading = false
    validationTimer?: number = undefined

    tagForm: Form<TagForm> = {
        valid: false,
        pending: false,
        data: {
            type: this.type,
            name: '',
            color: TAG_COLOR_OPTIONS[0],
        },
        rules: {
            name: [
                (value: string) => !!value || 'Tag name is required',
                (value: string) => value.length <= 20 || 'Max 20 characters',
            ],
        },
    }

    get form(): Vue & { resetValidation: () => void } {
        return this.$refs.form as Vue & { resetValidation: () => void }
    }

    get inputName(): Vue & { focus: () => void } {
        return this.$refs.name as Vue & { focus: () => void }
    }

    get title(): string {
        return `${this.tag ? 'Update' : 'Create'} ${this.type} tag`
    }

    beforeMount(): void {
        this.populateForm()
    }

    @Watch('isDialogOpen')
    private onIsDialogOpenChanges(value: boolean): void {
        if (value) {
            this.confirmDelete = false
            this.nameUniqueError = null
            this.form.resetValidation()
            this.populateForm()
            if (!this.tag) this.inputName.focus()
        }
    }

    private populateForm(): void {
        if (this.tag) {
            const { type, name, color } = this.tag
            this.tagForm.data.type = type
            this.tagForm.data.name = name
            this.tagForm.data.color = color
        } else {
            this.tagForm.data.type = this.type
            this.tagForm.data.name = ''
            this.tagForm.data.color = TAG_COLOR_OPTIONS[0]
        }
    }

    validateName(value: string): void {
        clearTimeout(this.validationTimer)

        if (value === '') {
            this.nameUniqueError = null
            return
        }

        this.tagForm.pending = true
        this.validationTimer = setTimeout(() => this.isNameUnique(value), 300)
    }

    private isNameUnique(name: string): void {
        const params: IsTagNameUniqueParams = {
            type: this.type,
            name,
            exclude_id: this.tag?.id,
        }

        this.inputNameLoading = true
        tagService
            .isNameUnique(params)
            .then((response: any) => {
                this.nameUniqueError = !response.body.unique
                    ? 'A tag with that name already exist'
                    : null
            })
            .catch((error: any) => console.error(error))
            .finally(() => {
                this.inputNameLoading = false
                this.tagForm.pending = false
            })
    }

    selectColor(color: string): void {
        this.tagForm.data.color = color
        this.colorPicker = false
    }

    emitSubmitEvent(): void {
        if (!this.tagForm.valid || this.tagForm.pending) return

        if (this.tag) this.$emit('update', this.tag.id, this.tagForm.data)
        else this.$emit('create', this.tagForm.data)
    }

    emitDeleteTag(): void {
        if (!this.confirmDelete) {
            this.confirmDelete = true
            return
        }

        if (this.tag) this.$emit('delete', this.tag.id)
    }

    emitCloseEvent(): void {
        this.$emit('close')
    }
}
