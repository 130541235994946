var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex flex-column flex-sm-row align-stretch column-gap-2 row-gap-1 mb-3"},[_c('h4',{staticClass:"text-h6 text-sm-h5 flex-grow-1"},[_vm._v("Settings")]),_c('div',{staticClass:"d-flex gap-2"},[_c('ConfirmDialog',{on:{"confirm":function($event){return _vm.toggleProjectArchiveState()}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"flex-grow-1 flex-sm-grow-0",attrs:{"outlined":!_vm.project.archived,"small":_vm.$vuetify.breakpoint.xsOnly,"color":"accent"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-archive")]),_vm._v(" "+_vm._s(_vm.project.archived ? 'unarchive' : 'archive')+" ")],1)]}},{key:"icon",fn:function(){return [_c('v-icon',{attrs:{"x-large":""}},[_vm._v("mdi-archive")])]},proxy:true}])},[_c('span',[_vm._v(" Are you sure to "+_vm._s(this.project.archived ? 'unarchive' : 'archive')+" this project ? ")])]),(_vm.project.archived)?[_c('ConfirmDialog',{on:{"confirm":function($event){return _vm.deleteProject()}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"flex-grow-1 flex-sm-grow-0",attrs:{"outlined":"","small":_vm.$vuetify.breakpoint.xsOnly,"color":"error"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-trash-can")]),_vm._v(" delete ")],1)]}},{key:"icon",fn:function(){return [_c('v-icon',{attrs:{"x-large":""}},[_vm._v("mdi-trash-can")])]},proxy:true}],null,false,3953043228)},[_c('p',{staticClass:"mb-1"},[_vm._v("Are you sure to delete this project ?")]),_c('p',{staticClass:"mb-0 font-italic",staticStyle:{"font-size":"1.1rem"}},[_vm._v(" All related tasks will be deleted ")])])]:_vm._e()],2)]),_c('v-form',{ref:"form",staticClass:"form-wrapper",on:{"submit":function($event){$event.preventDefault();return _vm.updateProject()}},model:{value:(_vm.projectForm.valid),callback:function ($$v) {_vm.$set(_vm.projectForm, "valid", $$v)},expression:"projectForm.valid"}},[_c('v-text-field',{staticClass:"mb-2",attrs:{"rules":_vm.projectForm.rules.name,"disabled":_vm.project.archived,"label":"Name","counter":"50","required":""},model:{value:(_vm.projectForm.data.name),callback:function ($$v) {_vm.$set(_vm.projectForm.data, "name", $$v)},expression:"projectForm.data.name"}}),_c('v-textarea',{staticClass:"mb-2",attrs:{"rules":_vm.projectForm.rules.description,"disabled":_vm.project.archived,"label":"Description","counter":"500","required":"","rows":"2","auto-grow":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }if(!$event.ctrlKey){ return null; }return _vm.updateProject()}},model:{value:(_vm.projectForm.data.description),callback:function ($$v) {_vm.$set(_vm.projectForm.data, "description", $$v)},expression:"projectForm.data.description"}}),_c('h6',{staticClass:"text-h6 grey--text",class:{
                'text--lighten-2': !this.project.archived,
                'text--darken-2': this.project.archived,
            }},[_c('v-icon',{attrs:{"small":"","color":this.project.archived ? 'grey darken-2' : 'grey lighten-2'}},[_vm._v(" mdi-tag ")]),_vm._v(" Tags ")],1),_c('TagSearch',{staticClass:"mb-3",attrs:{"selected-tags":_vm.tagList,"disabled":_vm.project.archived,"type":"project"},on:{"update:selectedTags":function($event){_vm.tagList=$event},"update:selected-tags":function($event){_vm.tagList=$event}}}),(_vm.tagList.length > 0)?_c('div',{staticClass:"tag-wrapper"},_vm._l((_vm.tagList),function(tag){return _c('TagChip',{key:tag.id,attrs:{"tag":tag,"disabled":_vm.project.archived,"clearable":true},on:{"clear":function($event){return _vm.removeTag($event)}}})}),1):_vm._e(),(!_vm.project.archived)?_c('div',{staticClass:"d-flex justify-end mb-5"},[_c('v-btn',{attrs:{"color":"success","type":"submit","block":_vm.$vuetify.breakpoint.xsOnly,"disabled":!_vm.projectForm.valid || _vm.isFormUntouched}},[_vm._v(" update ")])],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }