


























































































































import { projectService } from '@/api/project.api'
import { ProjectDetail, ProjectPostOrPatch } from '@/models/project.model'
import { projectActions } from '@/store/modules/project.store'
import { Component, Vue, Watch } from 'vue-property-decorator'
import TagSearch from '@/views/components/tag/TagSearch.vue'
import TagChip from '@/views/components/tag/TagChip.vue'
import { Tag } from '@/models/tag.model'
import { Form } from '@/models/common.model'
import deepEqual from 'deep-equal'
import ConfirmDialog from '@/components/ConfirmDialog.vue'

@Component({ components: { TagSearch, TagChip, ConfirmDialog } })
export default class ProjectSettings extends Vue {
    tagList: Tag[] = []

    projectForm: Form<ProjectPostOrPatch> = {
        valid: false,
        data: {
            name: this.project.name,
            description: this.project.description,
            tagIds: [],
        },
        rules: {
            name: [
                (value: string) => !!value || 'Project name is required',
                (value: string) => value.length <= 50 || 'Max 50 characters',
            ],
            description: [
                (value: string) => !!value || 'Project description is required',
                (value: string) => value.length <= 500 || 'Max 500 characters',
            ],
        },
    }

    get project(): ProjectDetail {
        return this.$store.state.project.currentProject
    }

    get isFormUntouched(): boolean {
        return (
            this.projectForm.data.name === this.project.name &&
            this.projectForm.data.description === this.project.description &&
            deepEqual(
                this.projectForm.data.tagIds,
                this.project.tags.map(({ id }) => id)
            )
        )
    }

    get form(): Vue & { resetValidation: () => void } {
        return this.$refs.form as Vue & { resetValidation: () => void }
    }

    @Watch('project.tags', { deep: true, immediate: true })
    private onProjectTagsChanges(value: Tag[]): void {
        this.tagList = value
    }

    @Watch('tagList', { immediate: true })
    private onTagListChanges(value: Tag[]): void {
        this.projectForm.data.tagIds = value.map(({ id }) => id)
    }

    removeTag(id: number): void {
        this.tagList = this.tagList.filter(tag => tag.id !== id)
    }

    toggleProjectArchiveState(): void {
        this.$store.dispatch(projectActions.updateProperties, {
            id: this.project.id,
            data: { archived: !this.project.archived },
        })
        this.resetForm()
    }

    resetForm(): void {
        this.projectForm.data = {
            name: this.project.name,
            description: this.project.description,
            tagIds: [],
        }
        this.form.resetValidation()
    }

    updateProject(): void {
        this.$store.dispatch(projectActions.updateProperties, {
            id: this.project.id,
            data: this.projectForm.data,
        })
    }

    deleteProject(): void {
        projectService.deleteProject(this.project.id).then(
            () => {
                this.$router.push({ name: 'project-list' })
            },
            (error: any) => {
                console.error(error)
            }
        )
    }
}
