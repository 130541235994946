
























import { Component, Prop, Vue } from 'vue-property-decorator'
import { authApi } from '@/api'
import { authService } from '@/services'

@Component
export default class ConfirmEmail extends Vue {
    @Prop({ default: '', required: true }) token!: string

    state: 'valid' | 'invalid' | null = null

    created(): void {
        authApi
            .confirmEmail({ token: this.token })
            .then(() => {
                this.state = 'valid'
                if (authService.isAuthenticated()) {
                    authService.removeToken()
                    authService.resetStore()
                }
            })
            .catch(() => (this.state = 'invalid'))
    }
}
