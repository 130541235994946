

































































































import { Task, TaskPatch } from '@/models/task.model'
import TaskDialog from '@/views/components/task/TaskDialog.vue'
import { Component, Prop, Vue } from 'vue-property-decorator'
import TagGroup from '@/views/components/tag/TagGroup.vue'
import ConfirmDialog from '@/components/ConfirmDialog.vue'
import { getDialogWidth } from '@/utils/dialog.utils'

@Component({
    methods: { getDialogWidth },
    components: { TaskDialog, ConfirmDialog, TagGroup },
})
export default class TaskCard extends Vue {
    @Prop({ required: true }) task!: Task
    @Prop({ default: false }) disabled!: boolean
    @Prop({ default: true }) displayOptions!: boolean
    @Prop({ default: true }) completable!: boolean
    @Prop({ default: false }) small!: boolean
    @Prop({ default: false }) selected!: boolean
    @Prop({ default: 2 }) elevation!: number
    @Prop({ default: null }) color!: string | null
    @Prop({ default: 'task' }) itemName!: string

    taskMenu = false
    taskDialog = false
    uncompleteConfirmDialog = false
    deleteConfirmDialog = false

    get cardColor(): string | null {
        return this.task.completed || this.selected ? 'green darken-2' : this.color
    }

    openEditDialog(): void {
        this.taskDialog = true
    }

    openDeleteDialog(): void {
        this.deleteConfirmDialog = true
    }

    openUncompleteDialog(): void {
        this.uncompleteConfirmDialog = true
    }

    emitToggleStateEvent(): void {
        this.$emit('toggle-state', this.task.id, !this.task.completed)
    }

    emitUpdateEvent(data: TaskPatch): void {
        this.taskDialog = false
        this.$emit('update', this.task.id, data)
    }

    emitDeleteEvent(): void {
        this.$emit('delete', this.task.id)
    }
}
