








































































import EmptyListDisplay from '@/components/EmptyListDisplay.vue'
import { ProjectDetail } from '@/models/project.model'
import { SectionTask } from '@/models/section.model'
import { projectActions } from '@/store/modules/project.store'
import ProjectSectionItem from '@/views/project/project-detail/components/ProjectSectionItem.vue'
import SectionDialog from '@/views/project/project-detail/components/SectionDialog.vue'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import ProgressDisk from '@/components/ProgressDisk.vue'
import { getDialogWidth } from '@/utils/dialog.utils'

@Component({
    methods: { getDialogWidth },
    components: {
        ProgressDisk,
        ProjectSectionItem,
        SectionDialog,
        EmptyListDisplay,
    },
})
export default class ProjectSection extends Vue {
    @Prop({ default: 0 }) sectionId!: number

    sectionDialog = false
    sectionTabs = 0

    get project(): ProjectDetail {
        return this.$store.state.project.currentProject
    }

    get sections(): SectionTask[] {
        return this.project.sections
    }

    @Watch('sectionId', { immediate: true })
    onSectionIdChanges(value: number): void {
        this.sectionTabs = this.sections.findIndex(({ id }) => id === value) ?? 0
    }

    createSection(data: { name: string }): void {
        this.sectionDialog = false
        this.$store
            .dispatch(projectActions.section.addSection, {
                name: data.name,
                projectId: this.project.id,
            })
            .then(() => {
                this.sectionTabs = 0
            })
    }

    changeRouteParam(index: number): void {
        this.$router.replace({
            params: { ...this.$route.params, sectionId: `${this.sections[index].id}` },
        })
    }
}
