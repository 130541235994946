














import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class MainTitle extends Vue {
    @Prop({ required: false }) icon?: string
}
