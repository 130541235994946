




































import { Component, Vue } from 'vue-property-decorator'
import ProgressWheel from '@/components/ProgressWheel.vue'
import { ProgressWheelMode, Preferences } from '@/models/preferences.model'
import { preferencesActions } from '@/store/modules/preferences.store'
import TertiaryTitle from '@/components/TertiaryTitle.vue'

@Component({ components: { TertiaryTitle, ProgressWheel } })
export default class SettingsPreferences extends Vue {
    ProgressWheelMode = ProgressWheelMode

    get preferences(): Preferences | undefined {
        return this.$store.state.preferences.preferences
    }

    get progressWheelSize(): 'x-small' | 'small' | 'medium' | 'large' | 'x-large' {
        if (this.$vuetify.breakpoint.xsOnly) return 'x-small'
        if (this.$vuetify.breakpoint.smAndDown) return 'small'
        else if (this.$vuetify.breakpoint.width < 1600) return 'medium'
        else return 'x-large'
    }

    updatePreferences(progressWheelMode: ProgressWheelMode): void {
        this.$store.dispatch(preferencesActions.updatePreferences, { progressWheelMode })
    }
}
