




























































































































import { DailyTask } from '@/models/daily-task.model'
import { ProjectDetail } from '@/models/project.model'
import { Task } from '@/models/task.model'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import TaskCard from '@/views/components/task/TaskCard.vue'
import TagGroup from '@/views/components/tag/TagGroup.vue'
import ProgressDisk from '@/components/ProgressDisk.vue'
import TaskCounter from '@/components/TaskCounter.vue'

@Component({ components: { TaskCounter, TaskCard, TagGroup, ProgressDisk } })
export default class DailyUpdateProjectListItem extends Vue {
    @Prop({ required: true }) project!: ProjectDetail
    @Prop({ required: true }) dailyTaskList!: DailyTask[]
    @Prop({ required: true }) selected!: boolean
    @Prop({ default: 0 }) sectionSelected!: number

    sectionTab = 0

    get taskBySection(): { id: number; name: string; tasks: Task[] }[] {
        const sections = this.project.sections
            .filter(({ tasks }) => tasks.some(({ completed }) => !completed))
            .map(section => ({
                id: section.id,
                name: section.name,
                tasks: section.tasks,
            }))

        if (this.project.tasks.some(({ completed }) => !completed))
            sections.unshift({
                id: 0,
                name: 'General tasks',
                tasks: this.project.tasks,
            })

        return sections
    }

    get allTasks(): Task[] {
        return this.project.tasks.concat(this.project.sections.map(s => s.tasks).flat())
    }

    get allTasksCompleted(): Task[] {
        return this.allTasks.filter(task => task.completed)
    }

    get allTasksUncompleted(): Task[] {
        return this.allTasks.filter(task => !task.completed)
    }

    get percentageOfTaskCompleted(): number {
        return (this.allTasksCompleted.length / this.allTasks.length) * 100
    }

    @Watch('sectionSelected')
    onSectionSelectedChanges(value: number): void {
        this.sectionTab = this.taskBySection.findIndex(({ id }) => id === value) ?? 0
    }

    isTaskSelected(task: Task): boolean {
        return this.dailyTaskList.some((dailyTask: DailyTask) => task.id === dailyTask.task?.id)
    }

    selectProject(): void {
        if (this.allTasksUncompleted.length === 0 || this.selected) return

        this.sectionTab = 0
        this.$emit('update:selected', true)
    }

    unselectProject(): void {
        this.$emit('update:selected', false)
    }

    selectTask(task: Task): void {
        if (this.isTaskSelected(task)) return

        this.$emit('select-task', { taskId: task.id })
    }
}
