var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.$vuetify.breakpoint.xsOnly)?_c('v-btn',{staticClass:"mb-1",attrs:{"disabled":_vm.disabled,"small":""},on:{"click":function($event){return _vm.$emit('new-section')}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" section ")],1):_vm._e(),_c('div',{staticClass:"d-flex align-center justify-ends gap-2 mb-2"},[_c('h3',{staticClass:"section-title text-h6"},[_vm._v(" "+_vm._s(_vm.section.name)+" "),_c('v-dialog',{attrs:{"width":_vm.getDialogWidth(),"fullscreen":_vm.$vuetify.breakpoint.smAndDown},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"disabled":_vm.disabled,"small":_vm.$vuetify.breakpoint.mdAndDown,"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-pencil")])],1)]}}]),model:{value:(_vm.sectionDialog),callback:function ($$v) {_vm.sectionDialog=$$v},expression:"sectionDialog"}},[_c('SectionDialog',{attrs:{"section":_vm.section,"is-dialog-open":_vm.sectionDialog},on:{"submit":_vm.updateSection,"delete":_vm.deleteSection,"close":function($event){_vm.sectionDialog = false}}})],1)],1),(_vm.$vuetify.breakpoint.smAndUp)?_c('v-btn',{attrs:{"disabled":_vm.disabled,"small":_vm.$vuetify.breakpoint.mdAndDown || true},on:{"click":function($event){return _vm.$emit('new-section')}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" section ")],1):_vm._e()],1),_c('v-row',{staticClass:"flex-wrap-reverse flex-sm-nowrap",attrs:{"no-gutters":_vm.$vuetify.breakpoint.xsOnly}},[_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.smAndUp ? 9 : 12}},[_c('div',{staticClass:"d-flex align-center mb-2"},[(_vm.section.tasks.length > 0)?_c('FilterChip',{attrs:{"color":"green darken-2","icon":"mdi-trophy"},model:{value:(_vm.displayCompletedTask),callback:function ($$v) {_vm.displayCompletedTask=$$v},expression:"displayCompletedTask"}},[_vm._v(" Completed ")]):_vm._e(),_c('v-spacer'),_c('v-dialog',{attrs:{"width":_vm.getDialogWidth(),"fullscreen":_vm.$vuetify.breakpoint.smAndDown},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"disabled":_vm.disabled,"small":_vm.$vuetify.breakpoint.smAndDown}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" task ")],1)]}}]),model:{value:(_vm.taskDialog),callback:function ($$v) {_vm.taskDialog=$$v},expression:"taskDialog"}},[_c('TaskDialog',{attrs:{"is-dialog-open":_vm.taskDialog},on:{"create":_vm.createTask,"close":function($event){_vm.taskDialog = false}}})],1)],1),(!_vm.displayCompletedTask)?[(_vm.uncompletedTasks.length > 0)?_vm._l((_vm.uncompletedTasks),function(task){return _c('TaskCard',{key:task.id,staticClass:"mb-2",attrs:{"task":task,"disabled":_vm.disabled},on:{"toggle-state":_vm.toggleTaskState,"update":_vm.updateTask,"delete":_vm.deleteTask}})}):(
                        _vm.section.tasks.length > 0 &&
                        _vm.section.tasks.length === _vm.completedTasks.length
                    )?[_c('EmptyListDisplay',{staticClass:"my-3",attrs:{"message":"You completed all the tasks of this section!"},scopedSlots:_vm._u([{key:"img",fn:function(){return [_c('img',{staticClass:"empty-img",attrs:{"src":require("../../../../assets/all_task_completed.svg"),"alt":"All tasks completed!"}})]},proxy:true}],null,false,3065767020)})]:[_c('EmptyListDisplay',{staticClass:"my-3",attrs:{"message":"This section has no task yet."},scopedSlots:_vm._u([{key:"img",fn:function(){return [_c('img',{staticClass:"empty-img",attrs:{"src":require("../../../../assets/project-no-tasks.svg"),"alt":"No tasks"}})]},proxy:true}],null,false,986744971)})]]:[(_vm.completedTasks.length > 0)?_vm._l((_vm.completedTasks),function(task){return _c('TaskCard',{key:task.id,staticClass:"mb-2",attrs:{"task":task,"disabled":_vm.disabled},on:{"toggle-state":_vm.toggleTaskState,"update":_vm.updateTask,"delete":_vm.deleteTask}})}):[_c('EmptyListDisplay',{staticClass:"my-3",attrs:{"message":"You didn't completed any tasks yet!"},scopedSlots:_vm._u([{key:"img",fn:function(){return [_c('img',{staticClass:"empty-img",attrs:{"src":require("../../../../assets/project-no-tasks.svg"),"alt":"No tasks completed"}})]},proxy:true}])})]]],2),_c('v-col',{attrs:{"cols":_vm.$vuetify.breakpoint.smAndUp ? 3 : 12}},[_c('v-scale-transition',{attrs:{"origin":"center"}},[(_vm.section.tasks.length > 0)?_c('div',{staticClass:"d-flex flex-column align-center justify-center"},[(_vm.$vuetify.breakpoint.smAndUp)?[_c('ProgressWheel',{attrs:{"mode":_vm.preferences.progressWheelMode,"value":_vm.completedTasks.length,"max":_vm.section.tasks.length,"size":_vm.progressWheelSize,"color":"green accent-2"}})]:[_c('v-progress-linear',{attrs:{"color":"green accent-2","value":_vm.completedTasksPercentage,"rounded":""}}),_c('div',[_vm._v(_vm._s(_vm.completedTasks.length)+" / "+_vm._s(_vm.section.tasks.length))])]],2):_vm._e()])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }