






























import { ProjectDetail } from '@/models/project.model'
import { projectActions } from '@/store/modules/project.store'
import { Component, Prop, Vue } from 'vue-property-decorator'
import SecondaryTitle from '@/components/SecondaryTitle.vue'

@Component({ components: { SecondaryTitle } })
export default class ProjectDetailComponent extends Vue {
    @Prop() private projectId!: number

    get project(): ProjectDetail | undefined {
        return this.$store.state.project.currentProject
    }

    mounted(): void {
        this.$store.dispatch(projectActions.retrieveProject, this.projectId)
    }

    destroyed(): void {
        this.$store.dispatch(projectActions.removeCurrentProject)
    }
}
