























































































































































import EmptyListDisplay from '@/components/EmptyListDisplay.vue'
import FilterChip from '@/components/FilterChip.vue'
import ProgressWheel from '@/components/ProgressWheel.vue'
import { CollectionDetail } from '@/models/collection.model'
import { Task, TaskPatch, TaskPost } from '@/models/task.model'
import { collectionActions } from '@/store/modules/collection.store'
import TaskDialog from '@/views/components/task/TaskDialog.vue'
import TaskCard from '@/views/components/task/TaskCard.vue'
import moment from 'moment'
import { Component, Vue } from 'vue-property-decorator'
import { Preferences } from '@/models/preferences.model'
import { getDialogWidth } from '@/utils/dialog.utils'

@Component({
    methods: { getDialogWidth },
    components: {
        TaskDialog,
        TaskCard,
        EmptyListDisplay,
        ProgressWheel,
        FilterChip,
    },
})
export default class CollectionGeneral extends Vue {
    taskDialog = false
    displayCompletedTask = false

    displayDescription = false
    isDescriptionOverflowing = false
    descriptionElement?: HTMLElement

    get preferences(): Preferences {
        return this.$store.state.preferences.preferences
    }

    get collection(): CollectionDetail {
        return this.$store.state.collection.currentCollection
    }

    get createdDate(): string {
        return moment(this.collection.createdOn).format('D MMMM Y')
    }

    get uncompletedTasks(): Task[] {
        return this.collection.tasks.filter(({ completed }) => !completed)
    }

    get completedTasks(): Task[] {
        return this.collection.tasks.filter(({ completed }) => completed)
    }

    get progressWheelSize(): 'x-small' | 'small' | 'medium' | 'large' | 'x-large' {
        if (this.$vuetify.breakpoint.xsOnly) return 'x-small'
        if (this.$vuetify.breakpoint.smAndDown) return 'small'
        else if (this.$vuetify.breakpoint.mdAndDown) return 'medium'
        else if (this.$vuetify.breakpoint.lgAndDown) return 'large'
        else return 'x-large'
    }

    mounted(): void {
        this.descriptionElement = this.$refs.description as HTMLElement
        if (this.$vuetify.breakpoint.xsOnly)
            this.isDescriptionOverflowing =
                this.descriptionElement.scrollHeight > this.descriptionElement.clientHeight
    }

    createTask(task: TaskPost): void {
        this.taskDialog = false
        task.collectionId = this.collection.id
        this.$store.dispatch(collectionActions.task.addTask, task)
    }

    toggleTaskState(id: number, completed: boolean): void {
        this.$store.dispatch(collectionActions.task.editTask, { id, data: { completed } })
    }

    updateTask(id: number, data: TaskPatch): void {
        this.$store.dispatch(collectionActions.task.editTask, { id, data })
    }

    deleteTask(id: number): void {
        this.$store.dispatch(collectionActions.task.deleteTask, id)
    }
}
