






































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Form } from '@/models/common.model'
import { CheckPasswordBody } from '@/models/auth.model'
import { authApi } from '@/api'
import { getConfirmDialogWidth } from '@/utils/dialog.utils'

@Component({
    methods: { getConfirmDialogWidth },
})
export default class ConfirmPasswordDialog extends Vue {
    @Prop({ default: false }) value!: boolean
    dialogState = false

    form: Form<CheckPasswordBody> = {
        valid: false,
        data: {
            password: '',
        },
        rules: {
            password: [
                (value: string) => !!value || 'Password is required',
                (value: string) => value.length <= 64 || 'Max 64 characters',
            ],
        },
    }

    showPassword = false
    passwordError: string | null = ''

    get formRef(): Vue & { resetValidation: () => void } {
        return this.$refs.form as Vue & { resetValidation: () => void }
    }

    get passwordInput(): Vue & { focus: () => void } {
        return this.$refs.passwordInput as Vue & { focus: () => void }
    }

    @Watch('value')
    private onDialogOpening(value: boolean): void {
        this.dialogState = value
        this.form.data.password = ''

        if (value) {
            // We need to wait for next tick to access the form and the input name

            this.$nextTick(() => {
                this.formRef.resetValidation()
                this.passwordInput.focus()
            })
        }
    }

    setDialogStateTo(value: boolean): void {
        this.dialogState = value
        this.$emit('input', value)
    }

    submit(): void {
        if (!this.form.valid || this.form.pending) return

        authApi
            .checkPassword(this.form.data)
            .then(() => {
                this.$emit('password-confirmed')
                this.setDialogStateTo(false)
            })
            .catch((error: any) => {
                if (error.status === 403) this.passwordError = 'Incorrect Password'
            })
    }
}
