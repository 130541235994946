





























































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Tag } from '@/models/tag.model'
import TagChip from '@/views/components/tag/TagChip.vue'

@Component({ components: { TagChip } })
export default class TagGroup extends Vue {
    @Prop({ required: true }) tagList!: Tag[]
    @Prop({ default: null }) maxTag!: number | null
    @Prop({ default: false }) small!: boolean
    @Prop({ default: false }) large!: boolean
    @Prop({ default: true }) iconTransparent!: boolean
    @Prop({ default: 4 }) zIndex!: number
    @Prop({ default: true }) autoShrink!: boolean
    @Prop({ default: false }) multiRow!: boolean

    smallMenuChip = false
    internalMaxTag: number | null = null
    tagGroup!: HTMLElement

    get displayedTags(): Tag[] {
        return this.tagList.slice(0, this.internalMaxTag ?? this.tagList.length)
    }

    get hiddenTags(): Tag[] {
        return this.internalMaxTag !== null ? this.tagList.slice(this.internalMaxTag) : []
    }

    mounted(): void {
        this.tagGroup = this.$refs.tagGroup as HTMLElement
        if (this.autoShrink) this.$nextTick(() => this.shrinkTagGroup()) // We need to use nextTick so the tagGroup render
    }

    updated(): void {
        if (this.autoShrink) this.shrinkTagGroup()
    }

    @Watch('maxTag', { immediate: true })
    private onMaxTagChanges(value: number | null): void {
        this.internalMaxTag = value ?? this.tagList.length
    }

    private shrinkTagGroup(): void {
        const isOverflowing = this.tagGroup.clientWidth < this.tagGroup.scrollWidth
        if (!isOverflowing) return

        if (this.internalMaxTag && this.internalMaxTag > 0) {
            this.internalMaxTag = this.internalMaxTag - 1
        } else if (!this.smallMenuChip) {
            this.smallMenuChip = true
        }
    }
}
