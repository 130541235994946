






































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { CommonTask, CommonTaskForm } from '@/models/common-task.model'
import { Form } from '@/models/common.model'
import { commonTaskService } from '@/api'
import { IsCommonTaskNameUniqueParams } from '@/api/common-task.api'
import TagSearch from '@/views/components/tag/TagSearch.vue'
import { Tag } from '@/models/tag.model'
import TagChip from '@/views/components/tag/TagChip.vue'
import { getDialogWidth } from '@/utils/dialog.utils'

@Component({
    methods: { getDialogWidth },
    components: { TagSearch, TagChip },
})
export default class CommonTaskDialog extends Vue {
    @Prop({ required: true }) value!: boolean
    @Prop({ required: false }) commonTask?: CommonTask

    nameUniqueError: string | null = null
    inputNameLoading = false
    validationTimer?: number = undefined

    tagList: Tag[] = []
    commonTaskForm: Form<CommonTaskForm> = {
        valid: false,
        pending: false,
        data: {
            name: '',
            tagIds: [],
        },
        rules: {
            name: [
                (value: string) => !!value || 'Name is required',
                (value: string) => value.length <= 50 || 'Max 50 characters',
            ],
        },
    }

    get title(): string {
        return this.commonTask ? 'Update Common Task' : 'New Common Task'
    }

    get form(): Vue & { resetValidation: () => void } {
        return this.$refs.form as Vue & { resetValidation: () => void }
    }

    get inputName(): Vue & { focus: () => void } {
        return this.$refs.name as Vue & { focus: () => void }
    }

    @Watch('value')
    private onDialogOpening(value: boolean): void {
        if (value) {
            // We need to wait for next tick to access the form and the input name
            this.$nextTick(() => {
                this.nameUniqueError = null
                this.form.resetValidation()
                this.populateForm()
                if (!this.commonTask) this.inputName.focus()
            })
        }
    }

    @Watch('tagList')
    private onTagListChanges(value: Tag[]): void {
        this.commonTaskForm.data.tagIds = value.map(({ id }) => id)
    }

    private populateForm(): void {
        if (this.commonTask) {
            this.commonTaskForm.data.name = this.commonTask.name
            this.commonTaskForm.data.tagIds = this.commonTask.tags.map(({ id }) => id)
            this.tagList = [...this.commonTask.tags]
        } else {
            this.commonTaskForm.data.name = ''
            this.commonTaskForm.data.tagIds = []
            this.tagList = []
        }
    }

    validateName(value: string): void {
        clearTimeout(this.validationTimer)

        if (value === '') {
            this.nameUniqueError = null
            return
        }

        this.commonTaskForm.pending = true
        this.validationTimer = setTimeout(() => this.isNameUnique(value), 300)
    }

    private isNameUnique(name: string): void {
        const params: IsCommonTaskNameUniqueParams = { name, exclude_id: this.commonTask?.id }

        this.inputNameLoading = true
        commonTaskService
            .isNameUnique(params)
            .then((response: any) => {
                this.nameUniqueError = !response.body.unique
                    ? 'A common task with that name already exist'
                    : null
            })
            .catch((error: any) => console.error(error))
            .finally(() => {
                this.inputNameLoading = false
                this.commonTaskForm.pending = false
            })
    }

    removeTag(id: number): void {
        this.tagList = this.tagList.filter(tag => tag.id !== id)
    }

    emitSubmit(): void {
        const { data, valid, pending } = this.commonTaskForm
        if (!valid || pending) return

        if (this.commonTask) this.$emit('update', { id: this.commonTask.id, data })
        else this.$emit('create', data)
    }

    closeDialog(): void {
        this.$emit('input', false)
    }
}
