
























import { Component, Prop, Vue } from 'vue-property-decorator'
import { Tag, TagForm } from '@/models/tag.model'
import TagDialog from '@/views/components/tag/TagDialog.vue'
import { getDialogWidth } from '@/utils/dialog.utils'

@Component({
    methods: { getDialogWidth },
    components: { TagDialog },
})
export default class TagCard extends Vue {
    @Prop({ required: true }) tag!: Tag

    tagDialog = false

    updateTag(id: number, data: TagForm): void {
        this.tagDialog = false
        this.$emit('update', { id, data })
    }

    deleteTag(id: number): void {
        this.tagDialog = false
        this.$emit('delete', id)
    }
}
