
































































































































































import { collectionService } from '@/api/collection.api'
import { dailyTaskService } from '@/api/daily-task.api'
import { projectService } from '@/api/project.api'
import EmptyListDisplay from '@/components/EmptyListDisplay.vue'
import { CollectionDetail } from '@/models/collection.model'
import {
    DailyTask,
    DailyTaskDisplayWrapper,
    DailyTaskPatch,
    DailyTaskPost,
    DailyUpdateTaskTab,
} from '@/models/daily-task.model'
import { ProjectDetail } from '@/models/project.model'
import DailyUpdateCollectionListItem from '@/views/daily/daily-update/steps/task/components/DailyUpdateCollectionListItem.vue'
import DailyUpdateProjectListItem from '@/views/daily/daily-update/steps/task/components/DailyUpdateProjectListItem.vue'
import DailyUpdateTaskList from '@/views/daily/daily-update/steps/task/components/DailyUpdateTaskList.vue'
import moment from 'moment'
import { Component, Emit, Prop, Vue } from 'vue-property-decorator'
import DailyUpdateCommonTask from '@/views/daily/daily-update/steps/task/components/DailyUpdateCommonTask.vue'
import { CommonTask } from '@/models/common-task.model'
import { commonTaskService } from '@/api'

// Todo : add btn to create project in no project svg
// Todo : add btn to create collection in no collection svg
@Component({
    components: {
        DailyUpdateProjectListItem,
        DailyUpdateCollectionListItem,
        DailyUpdateCommonTask,
        DailyUpdateTaskList,
        EmptyListDisplay,
    },
})
export default class DailyUpdateTask extends Vue {
    @Prop({ required: true }) private date!: string

    dailyTaskList: DailyTask[] = []
    projectList: DailyTaskDisplayWrapper<ProjectDetail>[] = []
    collectionList: DailyTaskDisplayWrapper<CollectionDetail>[] = []
    commonTaskList: CommonTask[] = []

    tab: DailyUpdateTaskTab = DailyUpdateTaskTab.Project
    projectSectionSelected: number = 0

    get isSomeProjectSelected(): boolean {
        return this.projectList.some(({ selected }) => selected)
    }

    get isSomeCollectionSelected(): boolean {
        return this.collectionList.some(({ selected }) => selected)
    }

    created(): void {
        this.retrieveDailyTaskList()
        this.retrieveProjectList()
        this.retrieveCollectionList()
        this.fetchCommonTaskList()
    }

    @Emit('daily-task-count')
    private emitDailyTaskCount(): number {
        return this.dailyTaskList.length
    }

    private retrieveDailyTaskList(): void {
        const date = moment().format('Y-MM-DD')
        dailyTaskService.getDailyTasksByDate(date).then(
            (response: any) => {
                this.dailyTaskList = response.body.content
                this.emitDailyTaskCount()
            },
            (error: any) => {
                console.error(error)
            }
        )
    }

    private retrieveProjectList(): void {
        projectService.getProjectListDetailed({ archived: false, has_uncompleted_task: true }).then(
            (response: any) => {
                this.projectList = response.body.content.map((project: ProjectDetail) => ({
                    content: project,
                    selected: false,
                }))
            },
            (error: any) => {
                console.error(error)
            }
        )
    }

    private retrieveCollectionList(): void {
        collectionService
            .getCollectionListDetailed({ archived: false, has_uncompleted_task: true })
            .then(
                (response: any) => {
                    this.collectionList = response.body.content.map(
                        (collection: CollectionDetail) => ({
                            content: collection,
                            selected: false,
                        })
                    )
                },
                (error: any) => {
                    console.error(error)
                }
            )
    }

    private fetchCommonTaskList(): void {
        commonTaskService
            .getCommonTaskList({ size: 0 })
            .then((response: any) => {
                this.commonTaskList = response.body.content
            })
            .catch((error: any) => console.error(error))
    }

    createDailyTask(data: DailyTaskPost): void {
        dailyTaskService
            .createDailyTask(data)
            .then((response: any) => {
                this.dailyTaskList.push(response.body)
                this.emitDailyTaskCount()
            })
            .catch((error: any) => console.error(error))
    }

    updateDailyTask(event: { id: number; data: DailyTaskPatch }): void {
        const { id, data } = event

        dailyTaskService
            .updateDailyTask(id, data)
            .then((response: any) => {
                const dailyTask = this.dailyTaskList.find(
                    (dailyTask: DailyTask) => dailyTask.id === response.body.id
                )
                if (dailyTask) Object.assign(dailyTask, response.body)
            })
            .catch((error: any) => console.error(error))
    }

    deleteDailyTask(id: number): void {
        dailyTaskService
            .deleteDailyTask(id)
            .then(() => {
                const dailyTaskIndex = this.dailyTaskList.findIndex(
                    (dailyTask: DailyTask) => dailyTask.id === id
                )
                if (dailyTaskIndex !== -1) {
                    this.dailyTaskList.splice(dailyTaskIndex, 1)
                    this.emitDailyTaskCount()
                }
            })
            .catch((error: any) => console.error(error))
    }

    select(event: { tab: DailyUpdateTaskTab; id: number; sectionId: number }): void {
        const { tab, id, sectionId } = event

        this.tab = tab
        this.resetSelectedItem()

        if (tab === DailyUpdateTaskTab.Project) {
            this.projectList = this.projectList.map(({ content }) => ({
                content,
                selected: content.id === id,
            }))
            this.projectSectionSelected = sectionId ?? 0
        } else {
            this.collectionList = this.collectionList.map(({ content }) => ({
                content,
                selected: content.id === id,
            }))
        }
    }

    resetSelectedItem(): void {
        this.projectList = this.projectList.map(item => ({ ...item, selected: false }))
        this.collectionList = this.collectionList.map(item => ({ ...item, selected: false }))
    }
}
