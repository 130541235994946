var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"rounded-lg",class:{ 'cursor-default': !_vm.clickable, 'mb-3': _vm.marginBottom, caret: _vm.caret },attrs:{"color":_vm.cardColor,"disabled":_vm.disabled,"ripple":false},on:{"click":function($event){return _vm.onEventCardClick()}}},[_c('v-card-text',{staticClass:"d-flex align-center pa-3 pa-sm-4"},[(_vm.showIcon)?_c('v-icon',{staticClass:"mr-2 mr-sm-3 mr-md-4",class:[_vm.getTextColor('icon')],attrs:{"large":_vm.$vuetify.breakpoint.smAndUp}},[_vm._v(" mdi-calendar-clock ")]):_vm._e(),(_vm.event.takesWholeDay)?[_c('v-icon',{staticClass:"mr-2",class:[_vm.getTextColor('icon')],attrs:{"title":"Takes whole day"}},[_vm._v(" mdi-white-balance-sunny ")])]:_vm._e(),_c('div',{staticClass:"flex-grow-1 d-flex flex-column overflow-hidden"},[_c('div',{staticClass:"date-text grey--text font-weight-bold",class:[_vm.getTextColor('date')]},[(_vm.event.takesWholeDay && !_vm.daySelected)?[_c('span',{attrs:{"title":"Date"}},[_vm._v(" "+_vm._s(_vm.dateFormat(_vm.event.startDate, 'DD/MM/YY'))+" ")])]:_vm._e(),(!_vm.event.takesWholeDay)?[_c('span',{attrs:{"title":"Start date"}},[(
                                        !_vm.daySelected ||
                                        (_vm.event.endDate &&
                                            !_vm.isDateEqual(_vm.event.startDate, _vm.event.endDate))
                                    )?[_vm._v(" "+_vm._s(_vm.dateFormat(_vm.event.startDate, 'DD/MM/YY'))+" ")]:_vm._e(),(_vm.event.startTime)?[_vm._v(" "+_vm._s(_vm.event.startTime)+" ")]:_vm._e()],2),(_vm.event.endDate)?[_c('v-icon',{staticClass:"mx-1",class:[_vm.getTextColor('date')],attrs:{"small":""}},[_vm._v(" mdi-arrow-right ")]),_c('span',{attrs:{"title":"End date"}},[(!_vm.isDateEqual(_vm.event.startDate, _vm.event.endDate))?[_vm._v(" "+_vm._s(_vm.dateFormat(_vm.event.endDate, 'DD/MM/YY'))+" ")]:_vm._e(),(_vm.event.endTime)?[_vm._v(" "+_vm._s(_vm.event.endTime)+" ")]:_vm._e()],2)]:_vm._e()]:_vm._e()],2),_c('h3',{staticClass:"text-body-2 text-sm-body-1 font-weight-bold white--text",class:[_vm.getTextColor('name')],attrs:{"title":_vm.event.name}},[_vm._v(" "+_vm._s(_vm.event.name)+" ")]),(_vm.event.description)?_c('span',{ref:"description",staticClass:"text-caption text-sm-body-2",class:[
                            {
                                'text-truncate': !_vm.displayDescription,
                                'cursor-pointer': _vm.isDescriptionOverflowing,
                            },
                            _vm.getTextColor('description') ],attrs:{"title":_vm.event.description}},[_vm._v(" "+_vm._s(_vm.event.description)+" ")]):_vm._e()]),(_vm.project)?[_c('router-link',{staticClass:"ml-2",attrs:{"to":{ name: 'project-detail', params: { id: _vm.project.id } }}},[_c('ProjectAvatar',{attrs:{"project":_vm.project,"hover":hover || _vm.$vuetify.breakpoint.xsOnly,"small":_vm.$vuetify.breakpoint.xsOnly}})],1)]:_vm._e()],2)],1)]}}])}),_c('v-dialog',{attrs:{"width":_vm.getDialogWidth(),"fullscreen":_vm.$vuetify.breakpoint.smAndDown},model:{value:(_vm.eventDialog),callback:function ($$v) {_vm.eventDialog=$$v},expression:"eventDialog"}},[_c('EventDialog',{attrs:{"event":_vm.event,"is-dialog-open":_vm.eventDialog,"related-to-date":_vm.relatedToDate},on:{"submit":_vm.emitUpdateEvent,"delete":_vm.emitDeleteEvent,"close":function($event){_vm.eventDialog = false}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }