







































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { getConfirmDialogWidth } from '@/utils/dialog.utils'

@Component({ methods: { getConfirmDialogWidth } })
export default class ConfirmDialog extends Vue {
    @Prop({ default: false }) value!: boolean
    dialogState = false

    @Watch('value')
    private onDialogOpening(value: boolean): void {
        this.dialogState = value
    }

    setDialogStateTo(value: boolean): void {
        this.dialogState = value
        this.$emit('input', value)
    }

    confirm(): void {
        this.$emit('confirm')
        this.setDialogStateTo(false)
    }
}
