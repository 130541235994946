




















import { Project } from 'src/models/project.model'
import { Section } from 'src/models/section.model'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { Location } from 'vue-router'

@Component
export default class SectionChip extends Vue {
    @Prop({ required: true }) section!: Section
    @Prop({ default: false }) ripple!: boolean
    @Prop({ default: false }) small!: boolean
    @Prop({ default: true }) navigateToDetail!: boolean

    get project(): Project {
        return this.section.project
    }

    get archived(): boolean {
        return this.project.archived
    }

    get detailLocation(): Location | undefined {
        if (!this.navigateToDetail) return
        return {
            name: 'project-detail-section',
            params: { id: `${this.project.id}`, sectionId: `${this.section.id}` },
        }
    }

    get title(): string {
        let title = `Go to : ${this.project.name} • ${this.section.name}`
        if (this.project.archived) title += ' (Archived)'
        return title
    }

    click(event: Event): void {
        if (this.project.archived) return

        this.$emit('click', event)
    }
}
