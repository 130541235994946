




































import { Component, Prop, Vue } from 'vue-property-decorator'
import DailyTaskCard from '@/views/daily/components/DailyTaskCard.vue'
import { DailyTask } from '@/models/daily-task.model'
import moment from 'moment/moment'

@Component({ components: { DailyTaskCard } })
export default class DailyDetailTaskTimeline extends Vue {
    @Prop({ required: true }) date!: string
    @Prop({ required: true }) dailyTaskList!: DailyTask[]

    get isToday(): boolean {
        return moment().isSame(this.date, 'day')
    }

    get numberOfDailyTaskCompleted(): number {
        return this.dailyTaskList.filter(dailyTask => dailyTask.completed).length
    }

    get numberOfDailyTaskUncompleted(): number {
        return this.dailyTaskList.filter(dailyTask => !dailyTask.completed).length
    }

    get taskText(): string {
        if (this.isToday) {
            if (this.numberOfDailyTaskUncompleted > 0)
                return `You have ${this.numberOfDailyTaskUncompleted} ${
                    this.numberOfDailyTaskUncompleted > 1 ? 'tasks' : 'task'
                } left to do today!`
            else return 'All tasks done for today! :)'
        } else {
            if (this.numberOfDailyTaskCompleted === this.dailyTaskList.length)
                return 'All tasks completed for that day! :)'
            else if (this.numberOfDailyTaskCompleted > 0)
                return `${this.numberOfDailyTaskCompleted} on ${this.dailyTaskList.length} tasks were completed that day`
            else return 'No tasks completed that day :('
        }
    }

    toggleDailyTask(dailyTask: DailyTask): void {
        this.$emit('toggle-daily-task', dailyTask)
    }
}
