



































import { Component, Prop, Vue } from 'vue-property-decorator'
import { CollectionList } from '@/models/collection.model'
import TaskCounter from '@/components/TaskCounter.vue'

@Component({
    components: { TaskCounter },
})
export default class CollectionCard extends Vue {
    @Prop({ required: true }) collection!: CollectionList

    get percentageOfCompletedTask(): number {
        return (this.collection.completedTaskCount / this.collection.taskCount) * 100
    }
}
