




























import { Component, Vue } from 'vue-property-decorator'
import MainTitle from '@/components/MainTitle.vue'

@Component({
    components: { MainTitle },
})
export default class Administration extends Vue {}
