var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{ selected: _vm.selected },on:{"click":function($event){return _vm.selectProject()}}},[_c('v-card',{staticClass:"project-card rounded-lg",class:{ 'cursor-pointer': !_vm.selected },attrs:{"disabled":_vm.allTasksUncompleted.length === 0}},[_c('v-progress-linear',{staticClass:"flex-shrink-0",attrs:{"value":_vm.percentageOfTaskCompleted,"color":"green accent-2","height":"4"}}),_c('v-card-text',{staticClass:"pa-3 pa-sm-4"},[_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"min-width-0"},[_c('h3',{staticClass:"text-body-h1 text-sm-h6 white--text",class:{ 'text-truncate': !_vm.selected },attrs:{"title":_vm.project.name}},[_vm._v(" "+_vm._s(_vm.project.name)+" ")]),(!_vm.selected)?[(_vm.project.tags.length > 0)?[_c('TagGroup',{staticClass:"mt-1",attrs:{"tag-list":_vm.project.tags,"small":true}})]:_vm._e()]:_vm._e()],2),(!_vm.selected)?[_c('v-spacer'),_c('TaskCounter',{staticClass:"ml-2",attrs:{"value":_vm.allTasksCompleted.length,"max":_vm.allTasks.length,"show-icon":false}})]:_vm._e(),(_vm.selected)?[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-btn',{staticClass:"mx-1",attrs:{"to":{ name: 'project-detail', params: { id: _vm.project.id } },"icon":"","small":"","color":hover ? 'grey' : 'grey darken-3',"title":"Go to project"}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-open-in-new")])],1)]}}],null,false,3141657377)}),_c('v-spacer'),_c('v-btn',{attrs:{"color":"red","icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.unselectProject()}}},[_c('v-icon',[_vm._v("mdi-close")])],1)]:_vm._e()],2),(_vm.selected)?[(_vm.project.tags.length > 0)?[_c('TagGroup',{staticClass:"my-1 flex-shrink-0",attrs:{"tag-list":_vm.project.tags}})]:_vm._e(),(_vm.taskBySection.length > 1 || _vm.taskBySection[0].id !== 0)?_c('v-tabs',{staticClass:"section-wrapper",attrs:{"color":"accent","hide-slider":"","show-arrows":""},model:{value:(_vm.sectionTab),callback:function ($$v) {_vm.sectionTab=$$v},expression:"sectionTab"}},_vm._l((_vm.taskBySection),function(section){return _c('v-tab',{key:("tab-" + (section.id))},[_c('span',{staticClass:"text-truncate",attrs:{"title":section.name}},[_vm._v(" "+_vm._s(section.name)+" ")]),(section.tasks.length > 0)?_c('ProgressDisk',{staticClass:"ml-1 flex-shrink-0",attrs:{"value":section.tasks.filter(function (ref) {
                    var completed = ref.completed;

                    return completed;
}).length,"max":section.tasks.length,"color":"green accent-2","title":((section.tasks.filter(function (ref) {
                                                    var completed = ref.completed;

                                                    return completed;
}).length) + " of " + (section.tasks.length) + " tasks completed")}}):_vm._e()],1)}),1):_vm._e(),_c('v-divider',{class:{
                        'mt-2': _vm.taskBySection.length === 1 && _vm.taskBySection[0].id === 0,
                    }}),_c('v-tabs-items',{staticClass:"tab-item-wrapper",attrs:{"touchless":""},model:{value:(_vm.sectionTab),callback:function ($$v) {_vm.sectionTab=$$v},expression:"sectionTab"}},_vm._l((_vm.taskBySection),function(section){return _c('v-tab-item',{key:("tab-item-" + (section.id))},[_c('div',{staticClass:"task-wrapper"},_vm._l((section.tasks.filter(
                                    function (ref) {
                                                        var completed = ref.completed;

                                                        return !completed;
                    }
                                )),function(task){return _c('TaskCard',{key:((section.name) + "-task-" + (task.id)),class:{ 'cursor-pointer': !_vm.isTaskSelected(task) },attrs:{"task":task,"selected":_vm.isTaskSelected(task),"small":true,"completable":false,"display-options":false,"elevation":"3","color":"grey darken-4"},nativeOn:{"click":function($event){return _vm.selectTask(task)}}})}),1)])}),1)]:_vm._e()],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }