















































import { Component, Vue } from 'vue-property-decorator'
import { FeedbackPost } from '@/models/feedback.model'
import { Form } from '@/models/common.model'
import { feedbackApi } from '@/api'
import MainTitle from '@/components/MainTitle.vue'

@Component({ components: { MainTitle } })
export default class FeedbackComponent extends Vue {
    form: Form<FeedbackPost> = {
        valid: false,
        data: {
            title: '',
            message: '',
        },
        rules: {
            title: [(value: string) => value.length <= 100 || 'Max 100 characters'],
            message: [(value: string) => value.length <= 2000 || 'Max 2000 characters'],
        },
    }

    get formRef(): Vue & { resetValidation: () => void } {
        return this.$refs.form as Vue & { resetValidation: () => void }
    }

    get canSubmit(): boolean {
        const { title, message } = this.form.data
        return this.form.valid && !!title && !!message
    }

    submit(): void {
        if (!this.canSubmit) return

        feedbackApi
            .createFeedback(this.form.data)
            .then(() => {
                console.log('Feedback submit!')
                this.form.data = {
                    title: '',
                    message: '',
                }
                this.formRef.resetValidation()
            })
            .catch((error: any) => console.error(error))
    }
}
