























































































































import { dailyTaskService } from '@/api/daily-task.api'
import { eventService } from '@/api/event.api'
import { DailyTask } from '@/models/daily-task.model'
import { EventModel } from '@/models/event.model'
import { dateFormat } from '@/pipes'
import { sortEvents } from '@/utils/event.utils'
import EventItemCard from '@/views/components/event/EventItemCard.vue'
import moment from 'moment'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import DailyTaskCard from '@/views/daily/components/DailyTaskCard.vue'
import DailyDetailTaskTimeline from '@/views/daily/daily-summary/components/DailyDetailTaskTimeline.vue'
import DailyDetailEventTimeline from '@/views/daily/daily-summary/components/DailyDetailEventTimeline.vue'
import { hideScroll, showScroll } from '@/utils/document.utils'
import EmptyListDisplay from '@/components/EmptyListDisplay.vue'

@Component({
    components: {
        EmptyListDisplay,
        DailyDetailEventTimeline,
        DailyDetailTaskTimeline,
        DailyTaskCard,
        EventItemCard,
    },
})
export default class DailyDetail extends Vue {
    @Prop({ default: false }) value!: boolean
    @Prop({ required: true }) date!: string

    dialogState = false
    dailyTaskList: DailyTask[] = []
    events: EventModel[] = []

    tab: 'task' | 'event' = 'task'
    isScrollingOnContent = false

    get numberOfDailyTaskCompleted(): number {
        return this.dailyTaskList.filter(dailyTask => dailyTask.completed).length
    }

    get isToday(): boolean {
        return moment().isSame(this.date, 'day')
    }

    @Watch('value')
    private onDialogOpening(value: boolean): void {
        this.dialogState = value
        this.tab = 'task'

        if (value) hideScroll()
        else showScroll()
    }

    @Watch('date', { immediate: false })
    private onDateChanges(): void {
        this.retrieveDailyTaskList()
        this.retrieveTodayEvents()
    }

    private retrieveDailyTaskList(): void {
        dailyTaskService.getDailyTasksByDate(this.date).then(
            (response: any) => (this.dailyTaskList = response.body.content),
            (error: any) => console.error(error)
        )
    }

    private retrieveTodayEvents(): void {
        eventService.getEvents({ date: this.date }).then(
            (response: any) =>
                (this.events = response.body.sort((a: EventModel, b: EventModel) =>
                    sortEvents(a, b, { handlePassedEvent: true })
                )),
            (error: any) => console.error(error)
        )
    }

    touchStartEvent() {
        // We detect if the touch-down is a scroll on the content
        const scrollableElement = document.querySelector('.v-dialog')
        if (!scrollableElement) return

        this.isScrollingOnContent = scrollableElement.scrollTop > 0
    }

    scrollDownEvent(): void {
        if (!this.isScrollingOnContent) this.setDialogStateTo(false)
    }

    switchTab(direction: 'right' | 'left'): void {
        if (
            !this.$vuetify.breakpoint.mdAndDown ||
            this.dailyTaskList.length === 0 ||
            this.events.length === 0
        )
            return

        if (direction === 'right') this.tab = 'task'
        else if (direction === 'left') this.tab = 'event'
    }

    setDialogStateTo(value: boolean): void {
        this.dialogState = value
        this.$emit('input', value)
    }

    toggleDailyTask(dailyTask: DailyTask): void {
        dailyTaskService.updateDailyTask(dailyTask.id, { completed: !dailyTask.completed }).then(
            (response: any) => {
                dailyTask.completed = response.body.completed
                this.emitDailyTaskCompletedEvent()
            },
            (error: any) => console.error(error)
        )
    }

    emitDailyTaskCompletedEvent(): void {
        this.$emit('daily-task-completed', this.date, this.numberOfDailyTaskCompleted)
    }

    dateFormat(date: string, format: string): string {
        return dateFormat(date, format)
    }
}
