












import { Component, Prop, Vue } from 'vue-property-decorator'
import { Tag } from '@/models/tag.model'

@Component
export default class TagChip extends Vue {
    @Prop({ required: true }) tag!: Tag
    @Prop({ default: false }) small!: boolean
    @Prop({ default: false }) xSmall!: boolean
    @Prop({ default: false }) clearable!: boolean
    @Prop({ default: false }) disabled!: boolean

    emitClearEvent(): void {
        this.$emit('clear', this.tag.id)
    }
}
