




















































import { dateFormat } from '@/pipes'
import DailyUpdateEvent from '@/views/daily/daily-update/steps/event/DailyUpdateEvent.vue'
import DailyUpdateTask from '@/views/daily/daily-update/steps/task/DailyUpdateTask.vue'
import { Component, Prop, Vue } from 'vue-property-decorator'
import MainTitle from '@/components/MainTitle.vue'
import SecondaryTitle from '@/components/SecondaryTitle.vue'

@Component({ components: { SecondaryTitle, DailyUpdateTask, DailyUpdateEvent } })
export default class DailyUpdate extends Vue {
    @Prop({ required: true }) readonly date!: string
    @Prop({ validator: value => value === 'task' || value === 'event' })
    readonly step!: 'task' | 'event'

    dailyStepper = 1
    dailyTaskCount = 0
    dailyEventCount = 0

    private beforeMount(): void {
        if (this.step === 'task') this.dailyStepper = 1
        else if (this.step === 'event') this.dailyStepper = 2
    }

    goToDailyDetail(): void {
        this.$router.push({ name: 'daily-summary', params: { date: this.date } })
    }

    onStepperChange(index: number): void {
        const step = index === 1 ? 'task' : 'event'
        this.$router.replace({ params: { step } })
    }

    dateFormat(date: string, format: string): string {
        return dateFormat(date, format)
    }
}
