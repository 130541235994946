






























import { Component, Prop, Vue } from 'vue-property-decorator'
import { CommonTask } from '@/models/common-task.model'
import CommonTaskCard from '@/views/components/common-task/CommonTaskCard.vue'
import EmptyListDisplay from '@/components/EmptyListDisplay.vue'
import { DailyTask } from '@/models/daily-task.model'

@Component({ components: { CommonTaskCard, EmptyListDisplay } })
export default class DailyUpdateCommonTask extends Vue {
    @Prop({ required: true, default: [] }) commonTaskList!: CommonTask[]
    @Prop({ required: true, default: [] }) dailyTaskList!: DailyTask[]

    selectCommonTask(id: number): void {
        if (this.isCommonTaskSelected(id)) return

        this.$emit('select-common-task', { commonTaskId: id })
    }

    isCommonTaskSelected(id: number): boolean {
        return this.dailyTaskList.some(({ commonTask }) => commonTask?.id === id)
    }
}
