









































































































import { collectionService } from '@/api/collection.api'
import { CollectionDetail, CollectionPatch } from '@/models/collection.model'
import { collectionActions } from '@/store/modules/collection.store'
import { Component, Vue } from 'vue-property-decorator'
import { Form } from '@/models/common.model'
import ConfirmDialog from '@/components/ConfirmDialog.vue'

@Component({
    components: { ConfirmDialog },
})
export default class CollectionSettings extends Vue {
    collectionForm: Form<CollectionPatch> = {
        valid: false,
        data: {
            name: this.collection.name,
            description: this.collection.description,
            itemName: this.collection.itemName,
        },
        rules: {
            name: [
                (value: string) => !!value || 'This field is required',
                (value: string) => value.length <= 50 || 'Max 50 characters',
            ],
            description: [
                (value: string) => !!value || 'This field is required',
                (value: string) => value.length <= 500 || 'Max 500 characters',
            ],
            itemName: [
                (value: string) => !!value || 'This field is required',
                (value: string) => value.length <= 15 || 'Max 15 characters',
            ],
        },
    }

    get collection(): CollectionDetail {
        return this.$store.state.collection.currentCollection
    }

    get form(): Vue & { resetValidation: () => void } {
        return this.$refs.form as Vue & { resetValidation: () => void }
    }

    get isFormUntouched(): boolean {
        return (
            this.collectionForm.data.name === this.collection.name &&
            this.collectionForm.data.description === this.collection.description &&
            this.collectionForm.data.itemName === this.collection.itemName
        )
    }

    toggleCollectionArchiveState(): void {
        this.$store.dispatch(collectionActions.updateProperties, {
            id: this.collection.id,
            data: { archived: !this.collection.archived },
        })
        this.resetForm()
    }

    resetForm(): void {
        this.collectionForm.data = {
            name: this.collection.name,
            description: this.collection.description,
            itemName: this.collection.itemName,
        }
        this.form.resetValidation()
    }

    updateCollection(): void {
        this.$store.dispatch(collectionActions.updateProperties, {
            id: this.collection.id,
            data: this.collectionForm.data,
        })
    }

    deleteCollection(): void {
        collectionService
            .deleteCollection(this.collection.id)
            .then(() => {
                this.$router.push({ name: 'collection-list' })
            })
            .catch((error: any) => console.error(error))
    }
}
