























































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { DailyTask, DailyTaskPost, DailyUpdateTaskTab } from '@/models/daily-task.model'
import EmptyListDisplay from '@/components/EmptyListDisplay.vue'
import DailyTaskFormCard from '@/views/daily/components/DailyTaskFormCard.vue'
import DailyTaskForm from '@/views/daily/components/DailyTaskForm.vue'

// todo : maybe change v-hover on daily task card
@Component({
    components: { DailyTaskForm, DailyTaskFormCard, EmptyListDisplay },
})
export default class DailyUpdateTaskList extends Vue {
    @Prop({ required: true }) dailyTaskList!: DailyTask[]

    selectedDailyTask: number | null = null
    createDailyTaskDisplayed = false

    @Watch('createDailyTaskDisplayed')
    private onCreateDailyTaskDisplayedChanges(value: boolean): void {
        if (value) this.selectedDailyTask = null
    }

    @Watch('selectedDailyTask')
    private onSelectedDailyTaskChanges(value: number | null): void {
        if (value !== null) this.createDailyTaskDisplayed = false
    }

    createDailyTask(data: DailyTaskPost): void {
        this.createDailyTaskDisplayed = false
        this.$emit('create', data)
    }

    updateDailyTask(id: number, data: DailyTaskPost): void {
        this.selectedDailyTask = null
        this.$emit('update', { id, data })
    }

    deleteDailyTask(id: number): void {
        this.$emit('delete', id)
    }

    select(event: { tab: DailyUpdateTaskTab; id: number; sectionId?: number }): void {
        this.$emit('select', event)
    }
}
