var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('TertiaryTitle',[_vm._v("Feedback")]),_c('v-data-table',{attrs:{"items":_vm.feedbackList,"headers":_vm.headers,"show-expand":"","single-expand":"","item-class":_vm.getItemClass},on:{"item-expanded":function($event){return _vm.feedbackExpanded($event)}},scopedSlots:_vm._u([{key:"item.user",fn:function(ref){
var value = ref.value;
return [_vm._v(_vm._s(value.username))]}},{key:"item.date",fn:function(ref){
var value = ref.value;
return [_vm._v(_vm._s(_vm.dateFormat(value, 'DD/MM/YYYY')))]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-end align-center"},[_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(item.isRead),expression:"item.isRead"}],attrs:{"icon":"","title":"Mark as unread"},on:{"click":function($event){return _vm.setFeedbackAsUnread(item.id)}}},[_c('v-icon',[_vm._v("mdi-email-mark-as-unread")])],1),_c('ConfirmDialog',{on:{"confirm":function($event){return _vm.deleteFeedback(item.id)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","title":"Delete"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-trash-can")])],1)]}},{key:"icon",fn:function(){return [_c('v-icon',{attrs:{"x-large":""}},[_vm._v("mdi-trash-can")])]},proxy:true}],null,true)},[_c('p',[_vm._v("Are you sure to delete this feedback ?")])])],1)]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"pa-5",attrs:{"colspan":headers.length}},[_vm._v(" "+_vm._s(item.message)+" ")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }