






















































import { Component, Prop, Vue } from 'vue-property-decorator'
import { CommonTask, CommonTaskForm } from '@/models/common-task.model'
import CommonTaskDialog from '@/views/components/common-task/CommonTaskDialog.vue'
import TagGroup from '@/views/components/tag/TagGroup.vue'
import ConfirmDialog from '@/components/ConfirmDialog.vue'

@Component({ components: { TagGroup, CommonTaskDialog, ConfirmDialog } })
export default class CommonTaskCard extends Vue {
    @Prop({ required: true }) readonly commonTask!: CommonTask
    @Prop({ default: true }) editable!: boolean
    @Prop({ default: false }) selected!: boolean

    commonTaskMenu = false
    editDialog = false
    deleteConfirmDialog = false

    openEditDialog(): void {
        this.editDialog = true
    }

    openDeleteDialog(): void {
        this.deleteConfirmDialog = true
    }

    emitUpdateEvent(event: { id: number; data: CommonTaskForm }): void {
        this.editDialog = false
        this.$emit('update', event)
    }

    emitDeleteEvent(): void {
        this.editDialog = false
        this.$emit('delete', this.commonTask.id)
    }
}
