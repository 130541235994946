

















import { Component, Vue } from 'vue-property-decorator'
import TheNavbar from '@/layout/components/TheNavbar.vue'
import TheHeader from '@/layout/components/TheHeader.vue'
import { User } from '@/models/user.model'

@Component({ components: { TheNavbar, TheHeader } })
export default class AuthenticatedLayout extends Vue {
    displayNavbar = !this.$vuetify.breakpoint.mobile
    headerMenu = false

    get user(): User | undefined {
        return this.$store.state.user.user
    }

    showNavbar(): void {
        if (!this.$vuetify.breakpoint.mobile) return

        this.displayNavbar = true
        this.headerMenu = false
    }

    hideNavbar(): void {
        if (!this.$vuetify.breakpoint.mobile) return

        this.displayNavbar = false
    }
}
