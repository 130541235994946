










































import { Component, Prop, Vue } from 'vue-property-decorator'
import { ProjectList } from '@/models/project.model'
import TagGroup from '@/views/components/tag/TagGroup.vue'
import TaskCounter from '@/components/TaskCounter.vue'

@Component({ components: { TaskCounter, TagGroup } })
export default class ProjectCard extends Vue {
    @Prop() private project!: ProjectList

    get percentageOfCompletedTask(): number {
        return (this.project.completedTaskCount / this.project.taskCount) * 100
    }
}
