



































import { Component, Prop, Vue } from 'vue-property-decorator'
import { DailyTaskAction } from '@/models/daily-task.model'
import {
    actionOptions,
    getActionChipColor,
    getActionChipTextColor,
    getLiteralFormOfDailyActionEnum,
} from '@/utils/daily-task.utils'

@Component
export default class DailyTaskActionChip extends Vue {
    @Prop({ required: true }) action!: DailyTaskAction | null
    @Prop({ default: false }) editable!: boolean

    dailyTaskActionItems = actionOptions

    get text(): string {
        return this.action ? getLiteralFormOfDailyActionEnum(this.action) : ''
    }

    get color(): string {
        return this.action ? getActionChipColor(this.action) : ''
    }

    get textColor(): string {
        return this.action ? getActionChipTextColor(this.action) : ''
    }

    updateAction(action: DailyTaskAction | null): void {
        this.$emit('update', action)
        this.$emit('update:action', action) // We send both event to meet all possibilities
    }
}
