var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex-grow-1 d-flex flex-column"},[(_vm.sections.length > 0)?[_c('v-tabs',{staticClass:"mb-2 flex-grow-0",attrs:{"color":"accent","hide-slider":"","show-arrows":"","background-color":"transparent"},on:{"change":function($event){return _vm.changeRouteParam($event)}},model:{value:(_vm.sectionTabs),callback:function ($$v) {_vm.sectionTabs=$$v},expression:"sectionTabs"}},_vm._l((_vm.sections),function(section){return _c('v-tab',{key:'tab-' + section.id},[_c('span',{staticClass:"text-truncate"},[_vm._v(_vm._s(section.name))]),(section.tasks.length > 0)?_c('ProgressDisk',{staticClass:"ml-1 flex-shrink-0",attrs:{"value":section.tasks.filter(function (ref) {
                    var completed = ref.completed;

                    return completed;
}).length,"max":section.tasks.length,"color":"green accent-2","title":((section.tasks.filter(function (ref) {
                    var completed = ref.completed;

                    return completed;
}).length) + " of " + (section.tasks.length) + " tasks completed")}}):_vm._e()],1)}),1),_c('v-tabs-items',{staticClass:"transparent",attrs:{"touchless":""},model:{value:(_vm.sectionTabs),callback:function ($$v) {_vm.sectionTabs=$$v},expression:"sectionTabs"}},_vm._l((_vm.sections),function(section){return _c('v-tab-item',{key:'tab-item-' + section.id},[_c('ProjectSectionItem',{attrs:{"section":section,"disabled":_vm.project.archived},on:{"new-section":function($event){_vm.sectionDialog = true}}})],1)}),1)]:[_c('EmptyListDisplay',{staticClass:"empty-list-display",scopedSlots:_vm._u([{key:"img",fn:function(){return [_c('img',{staticClass:"empty-list-display__img",attrs:{"src":require("../../../../assets/project_section.svg"),"alt":"No sections","width":"200"}})]},proxy:true},{key:"message",fn:function(){return [_c('div',{staticClass:"mb-2"},[_vm._v("This project has no section yet!")]),(!_vm.project.archived)?_c('v-btn',{attrs:{"small":_vm.$vuetify.breakpoint.smAndDown},on:{"click":function($event){_vm.sectionDialog = true}}},[_c('v-icon',{attrs:{"left":"","small":""}},[_vm._v("mdi-plus")]),_vm._v(" add a section ")],1):_vm._e()]},proxy:true}])})],_c('v-dialog',{attrs:{"width":_vm.getDialogWidth(),"fullscreen":_vm.$vuetify.breakpoint.smAndDown},model:{value:(_vm.sectionDialog),callback:function ($$v) {_vm.sectionDialog=$$v},expression:"sectionDialog"}},[_c('SectionDialog',{attrs:{"is-dialog-open":_vm.sectionDialog},on:{"submit":function($event){return _vm.createSection($event)},"close":function($event){_vm.sectionDialog = false}}})],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }