var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex-grow-1 d-flex flex-column"},[_c('div',{staticClass:"project-description"},[_c('div',{staticClass:"project-description__description"},[_c('h3',{staticClass:"text-h6 text-sm-h5 mb-1 mb-lg-2"},[_vm._v("Description")]),_c('v-card',{staticClass:"rounded-lg mb-3"},[_c('v-card-text',[_c('div',{ref:"description",staticClass:"project-description__description__content text-body-2",class:{
                            'display-description': _vm.displayDescription,
                            'cursor-pointer': _vm.isDescriptionOverflowing,
                        },on:{"click":function($event){_vm.displayDescription = !_vm.displayDescription}}},[_vm._v(" "+_vm._s(_vm.project.description)+" ")]),_c('div',{staticClass:"d-flex justify-end align-center mt-1",attrs:{"title":"Created on"}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-clock")]),_c('span',{staticClass:"font-italic ml-1"},[_vm._v(_vm._s(_vm.createdDate))])],1)])],1),(_vm.project.tags.length > 0)?[_c('TagGroup',{attrs:{"tag-list":_vm.project.tags,"max-tag":10,"large":true,"icon-transparent":false,"auto-shrink":false,"multi-row":true}})]:_vm._e()],2),_c('div',{staticClass:"project-description__progress-wheel"},[_c('ProgressWheel',{attrs:{"size":_vm.progressWheelSize,"mode":_vm.preferences.progressWheelMode,"value":_vm.allCompletedTasks.length,"max":_vm.allTasks.length,"color":"green accent-2"}})],1)]),_c('div',{staticClass:"d-flex flex-column flex-sm-row column-gap-2 row-gap-1 mt-5 mt-md-10 mb-2"},[_c('div',{staticClass:"d-flex align-center flex-grow-1"},[_c('h3',{staticClass:"text-h6 text-sm-h5 flex-grow-1"},[_vm._v("General Tasks")]),(_vm.$vuetify.breakpoint.xsOnly && _vm.project.tasks.length === 0)?[_c('v-dialog',{attrs:{"width":_vm.getDialogWidth(),"fullscreen":_vm.$vuetify.breakpoint.smAndDown},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"disabled":_vm.project.archived}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" task ")],1)]}}],null,false,287116519),model:{value:(_vm.taskDialog),callback:function ($$v) {_vm.taskDialog=$$v},expression:"taskDialog"}},[_c('TaskDialog',{attrs:{"is-dialog-open":_vm.taskDialog},on:{"create":_vm.createTask,"close":function($event){_vm.taskDialog = false}}})],1)]:_vm._e()],2),_c('div',{staticClass:"d-flex justify-space-between align-center gap-2"},[(_vm.project.tasks.length > 0)?_c('FilterChip',{staticClass:"flex-shrink-0",attrs:{"color":"green darken-2","icon":"mdi-trophy"},model:{value:(_vm.displayCompletedTask),callback:function ($$v) {_vm.displayCompletedTask=$$v},expression:"displayCompletedTask"}},[_vm._v(" Completed ")]):_vm._e(),(_vm.$vuetify.breakpoint.smAndUp || _vm.project.tasks.length > 0)?[_c('v-dialog',{attrs:{"width":_vm.getDialogWidth(),"fullscreen":_vm.$vuetify.breakpoint.smAndDown},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"disabled":_vm.project.archived,"block":_vm.$vuetify.breakpoint.xsOnly &&
                                _vm.project.tasks.length === 0 &&
                                false}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" task ")],1)]}}],null,false,4260358195),model:{value:(_vm.taskDialog),callback:function ($$v) {_vm.taskDialog=$$v},expression:"taskDialog"}},[_c('TaskDialog',{attrs:{"is-dialog-open":_vm.taskDialog},on:{"create":_vm.createTask,"close":function($event){_vm.taskDialog = false}}})],1)]:_vm._e()],2)]),(!_vm.displayCompletedTask)?[(_vm.uncompletedTasks.length > 0)?[_c('div',{staticClass:"task-wrapper"},_vm._l((_vm.uncompletedTasks),function(task){return _c('TaskCard',{key:task.id,attrs:{"task":task,"disabled":_vm.project.archived},on:{"toggle-state":_vm.toggleTaskState,"update":_vm.updateTask,"delete":_vm.deleteTask}})}),1)]:(
                _vm.project.tasks.length > 0 && _vm.project.tasks.length === _vm.completedTasks.length
            )?[_c('EmptyListDisplay',{staticClass:"empty-list-display",attrs:{"message":"You completed all the general tasks of this project!"},scopedSlots:_vm._u([{key:"img",fn:function(){return [_c('img',{staticClass:"empty-list-display__img",attrs:{"src":require("../../../../assets/all_task_completed.svg"),"alt":"All tasks completed"}})]},proxy:true}],null,false,3760914584)})]:[_c('EmptyListDisplay',{staticClass:"empty-list-display",attrs:{"message":"This project has no general task."},scopedSlots:_vm._u([{key:"img",fn:function(){return [_c('img',{staticClass:"empty-list-display__img",attrs:{"src":require("../../../../assets/project-no-tasks.svg"),"alt":"No tasks"}})]},proxy:true}],null,false,716235614)})]]:[(_vm.completedTasks.length > 0)?[_c('div',{staticClass:"task-wrapper"},_vm._l((_vm.completedTasks),function(task){return _c('TaskCard',{key:task.id,attrs:{"task":task,"disabled":_vm.project.archived},on:{"toggle-state":_vm.toggleTaskState,"update":_vm.updateTask,"delete":_vm.deleteTask}})}),1)]:[_c('EmptyListDisplay',{staticClass:"empty-list-display",attrs:{"message":"You didn't completed any general tasks yet!"},scopedSlots:_vm._u([{key:"img",fn:function(){return [_c('img',{staticClass:"empty-list-display__img",attrs:{"src":require("../../../../assets/project-no-tasks.svg"),"alt":"No tasks completed"}})]},proxy:true}])})]]],2)}
var staticRenderFns = []

export { render, staticRenderFns }