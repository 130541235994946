












































import ProjectChip from '@/components/ProjectChip.vue'
import { EventModel } from '@/models/event.model'
import { dateFormat } from '@/pipes'
import moment from 'moment'
import { Component, Emit, Prop, Vue } from 'vue-property-decorator'

@Component({ components: { ProjectChip } })
export default class EventTooltip extends Vue {
    @Prop({ required: true }) event!: EventModel

    get isEditDisabled(): boolean {
        return this.event.project ? this.event.project.archived : false
    }

    @Emit('update')
    emitUpdateEvent(): EventModel {
        return this.event
    }

    isDateEqual(date1: string, date2: string): boolean {
        return moment(date1).isSame(date2, 'day')
    }

    dateFormat(date: string, format: string): string {
        return dateFormat(date, format)
    }
}
