






















































import { Component, Prop, Vue } from 'vue-property-decorator'
import { Form } from '@/models/common.model'
import { ResetPasswordRequestBody } from '@/models/auth.model'
import { authApi } from '@/api'

@Component
export default class ResetPasswordRequest extends Vue {
    @Prop({ required: false, default: '' }) email?: string

    form: Form<ResetPasswordRequestBody> = {
        valid: false,
        data: {
            email: '',
        },
        rules: {
            email: [
                (value: string) => !!value || 'Email is required',
                (value: string) => value.length <= 100 || 'Max 100 characters',
                (value: string) =>
                    /^[\w-\.]+@([\w-]+\.)+[\w-]{2,8}$/.test(value) || 'Invalid e-mail address',
            ],
        },
    }

    resetPasswordRequested = false

    created(): void {
        if (this.email) this.form.data.email = this.email
    }

    get formRef(): Vue & { validate: () => boolean } {
        return this.$refs.formRef as Vue & { validate: () => boolean }
    }

    submit(): void {
        if (!this.formRef.validate()) return

        authApi
            .resetPasswordRequest(this.form.data)
            .then(() => (this.resetPasswordRequested = true))
            .catch((error: any) => console.error(error))
    }
}
