



































































import { projectService } from '@/api/project.api'
import EmptyListDisplay from '@/components/EmptyListDisplay.vue'
import FilterChip from '@/components/FilterChip.vue'
import { ProjectList, ProjectPostOrPatch } from '@/models/project.model'
import ProjectFormDialog from '@/views/project/components/ProjectFormDialog.vue'
import ProjectCard from '@/views/project/components/ProjectCard.vue'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import MainTitle from '@/components/MainTitle.vue'
import { getDialogWidth } from '@/utils/dialog.utils'

@Component({
    methods: { getDialogWidth },
    components: { MainTitle, ProjectCard, ProjectFormDialog, EmptyListDisplay, FilterChip },
})
export default class ProjectListComponent extends Vue {
    @Prop({ default: false }) archived!: boolean

    projectList: ProjectList[] = []
    projectDialog = false

    created(): void {
        this.retrieveProjectList({ archived: this.archived })
    }

    @Watch('archived')
    private onArchivedProjectsChanges(value: boolean): void {
        this.retrieveProjectList({ archived: value })
    }

    retrieveProjectList(params = {}): void {
        projectService.getProjectList(params).then(
            (response: any) => {
                this.projectList = response.body.content
            },
            (error: any) => {
                console.error(error)
            }
        )
    }

    createProject(projectForm: ProjectPostOrPatch): void {
        this.projectDialog = false
        projectService.createProject(projectForm).then(
            (response: any) => {
                this.$router.push({ name: 'project-detail', params: { id: response.body.id } })
            },
            (error: any) => {
                console.error(error)
            }
        )
    }

    toggleArchivedProject(): void {
        this.$router.replace({ query: { archived: (!this.archived).toString() } })
    }
}
