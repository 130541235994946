










































import { Component, Vue } from 'vue-property-decorator'

@Component
export default class TheNavbar extends Vue {
    private menu = [
        { name: 'Dashboard', icon: 'mdi-view-dashboard' },
        { name: 'Daily', link: { name: 'daily-summary' }, icon: 'mdi-trophy' },
        { name: 'Projects', link: { name: 'project-list' }, icon: 'mdi-briefcase-variant' },
        { name: 'Collections', link: { name: 'collection-list' }, icon: 'mdi-list-box' },
        { name: 'Agenda', link: { name: 'agenda' }, icon: 'mdi-calendar-month' },
    ]
}
