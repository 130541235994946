



























































import { Component, Vue } from 'vue-property-decorator'
import { UserChangePassword } from '@/models/user.model'
import { Form } from '@/models/common.model'
import { authApi, userApi } from '@/api'
import TertiaryTitle from '@/components/TertiaryTitle.vue'

@Component({
    components: { TertiaryTitle },
})
export default class ProfilePassword extends Vue {
    form: Form<UserChangePassword> = {
        valid: false,
        pending: false,
        data: {
            currentPassword: '',
            newPassword: '',
            confirmPassword: '',
        },
        rules: {
            currentPassword: [
                (value: string) => !!value || 'Password is required',
                (value: string) => value.length <= 64 || 'Max 64 characters',
            ],
            newPassword: [
                (value: string) => !!value || 'Password is required',
                (value: string) => value.length <= 64 || 'Max 64 characters',
            ],
            confirmPassword: [
                (value: string) => !!value || 'Password is required',
                (value: string) => value.length <= 64 || 'Max 64 characters',
            ],
        },
    }

    showCurrentPassword = false
    showNewPassword = false
    showConfirmPassword = false

    currentPasswordError: string | null = null
    newPasswordValidationErrors: string[] = []
    passwordMatchError: string | null = null
    private newPasswordValidationTimer?: number = undefined
    private passwordMatchTimer?: number = undefined

    get formRef(): Vue & { resetValidation: () => void } {
        return this.$refs.form as Vue & { resetValidation: () => void }
    }

    validatePasswordStrength(value: string): void {
        this.validatePasswordMatch()

        clearTimeout(this.newPasswordValidationTimer)
        if (value === '') {
            this.newPasswordValidationErrors = []
            return
        }

        this.form.pending = true
        this.newPasswordValidationTimer = setTimeout(() => this.testPasswordStrength(value), 400)
    }

    private testPasswordStrength(value: string): void {
        authApi
            .validatePassword({ password: value })
            .then((response: any) => (this.newPasswordValidationErrors = response.body.errors))
            .catch((error: any) => console.error(error))
            .finally(() => (this.form.pending = false))
    }

    validatePasswordMatch(): void {
        clearTimeout(this.passwordMatchTimer)
        const { newPassword, confirmPassword } = this.form.data

        if (!newPassword || !confirmPassword) {
            this.passwordMatchError = null
        }

        this.form.pending = true
        this.passwordMatchTimer = setTimeout(() => {
            this.passwordMatchError =
                newPassword && confirmPassword && newPassword !== confirmPassword
                    ? 'Passwords are not matching'
                    : null
            this.form.pending = false
        }, 300)
    }

    submit(): void {
        if (!this.form.valid || this.form.pending) return

        userApi
            .changePassword(this.form.data)
            .then(() => {
                this.form.data = { currentPassword: '', newPassword: '', confirmPassword: '' }
                this.formRef.resetValidation()
            })
            .catch((error: any) => {
                if (error.status === 403) this.currentPasswordError = 'Incorrect Password'
            })
    }
}
