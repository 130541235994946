









































































import { Component, Vue } from 'vue-property-decorator'
import { userApi } from '@/api'
import { dateFormat } from '@/pipes'
import { User } from '@/models/user.model'
import { DataTableHeader } from 'vuetify'
import ConfirmPasswordDialog from '@/components/ConfirmPasswordDialog.vue'
import TertiaryTitle from '@/components/TertiaryTitle.vue'

@Component({
    components: { TertiaryTitle, ConfirmPasswordDialog },
})
export default class AdministrationUser extends Vue {
    userList: User[] = []
    headers: DataTableHeader[] = [
        { text: 'Username', value: 'username' },
        { text: 'First name', value: 'firstName', align: 'center' },
        { text: 'Last name', value: 'lastName', align: 'center' },
        { text: 'Email', value: 'email' },
        { text: 'Account created', value: 'dateJoined', align: 'center' },
        { text: 'Last login', value: 'lastLogin', align: 'center' },
        { text: 'Active', value: 'isActive', align: 'center', sort: this.booleanSort },
        { text: 'Staff', value: 'isStaff', align: 'center', sort: this.booleanSort },
        { text: 'Actions', value: 'actions', align: 'center', sortable: false },
    ]
    loading = false

    get user(): User {
        return this.$store.state.user.user
    }

    created(): void {
        this.loading = true
        userApi
            .getUserList()
            .then((response: any) => {
                this.userList = response.body.content
            })
            .catch((error: any) => console.error(error))
            .finally(() => (this.loading = false))
    }

    resendActivationEmail(id: number): void {
        userApi
            .resendActivationEmail(id)
            .then(() => {
                // TODO : add notification
                console.log('Activation email resend successfully!')
            })
            .catch((error: any) => console.error(error))
    }

    changeUserAccountState(id: number, active: boolean): void {
        userApi
            .changeAccountState(id, { active })
            .then((response: any) => {
                const index = this.userList.findIndex(user => user.id === id)
                if (index !== -1) this.userList.splice(index, 1, response.body)
            })
            .catch((error: any) => console.error(error))
    }

    deleteUser(id: number): void {
        userApi
            .deleteUser(id)
            .then(() => {
                const index = this.userList.findIndex(user => user.id === id)
                if (index !== -1) this.userList.splice(index, 1)
            })
            .catch((error: any) => console.error(error))
    }

    dateFormat(date: string, format: string): string {
        return dateFormat(date, format)
    }

    booleanSort(a: boolean, b: boolean): number {
        if (a && !b) return -1
        else if (!a && b) return 1
        else return 0
    }
}
