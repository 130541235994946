






































































import { DailyTask } from '@/models/daily-task.model'
import { Task } from '@/models/task.model'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { CollectionDetail } from '@/models/collection.model'
import TaskCard from '@/views/components/task/TaskCard.vue'
import TaskCounter from '@/components/TaskCounter.vue'

@Component({ components: { TaskCounter, TaskCard } })
export default class DailyUpdateCollectionListItem extends Vue {
    @Prop() collection!: CollectionDetail
    @Prop() dailyTaskList!: DailyTask[]
    @Prop() selected!: boolean

    get tasks(): Task[] {
        return this.collection.tasks
    }

    get tasksCompleted(): Task[] {
        return this.tasks.filter(task => task.completed)
    }

    get tasksUncompleted(): Task[] {
        return this.tasks.filter(task => !task.completed)
    }

    get percentageOfTaskCompleted(): number {
        return (this.tasksCompleted.length / this.tasks.length) * 100
    }

    isTaskSelected(task: Task): boolean {
        return this.dailyTaskList.some((dailyTask: DailyTask) => task.id === dailyTask.task?.id)
    }

    selectCollection(): void {
        if (this.tasksUncompleted.length !== 0 && !this.selected)
            this.$emit('update:selected', true)
    }

    unselectCollection(): void {
        this.$emit('update:selected', false)
    }

    selectTask(task: Task): void {
        if (this.isTaskSelected(task)) return

        this.$emit('select-task', { taskId: task.id })
    }
}
