










































import { Component, Vue, Watch } from 'vue-property-decorator'
import { Form } from '@/models/common.model'
import { authService } from '@/services'
import { LoginPost } from '@/models/login.model'

@Component
export default class Login extends Vue {
    form: Form<LoginPost> = {
        valid: false,
        data: {
            email: '',
            password: '',
        },
    }

    credentialsError = false
    showPassword = false
    loading = false

    @Watch('form.data', { deep: true })
    private onFormDataChanges(data: LoginPost): void {
        this.credentialsError = false
        this.form.valid = !!data.email && !!data.password
    }

    login(): void {
        if (!this.form.valid) return

        this.loading = true
        authService
            .login(this.form.data)
            .then(() => {
                this.$store.dispatch('init').then(() => {
                    const next = this.$route.query.next
                    // We need to catch the error here when a navigation guard block the navigation
                    if (next && typeof next === 'string') this.$router.push(next).catch(() => {})
                    else this.$router.push({ name: 'home' })
                })
            })
            .catch(() => {
                this.credentialsError = true
            })
            .finally(() => (this.loading = false))
    }
}
