






































































import { collectionService } from '@/api/collection.api'
import EmptyListDisplay from '@/components/EmptyListDisplay.vue'
import FilterChip from '@/components/FilterChip.vue'
import { CollectionList, CollectionPost } from '@/models/collection.model'
import CollectionFormDialog from '@/views/collection/components/CollectionFormDialog.vue'
import CollectionCard from '@/views/collection/components/CollectionCard.vue'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import MainTitle from '@/components/MainTitle.vue'
import { getDialogWidth } from '@/utils/dialog.utils'

@Component({
    methods: { getDialogWidth },
    components: {
        MainTitle,
        CollectionFormDialog,
        CollectionCard,
        EmptyListDisplay,
        FilterChip,
    },
})
export default class CollectionListComponent extends Vue {
    @Prop({ default: false }) archived!: boolean

    collectionList: CollectionList[] = []
    collectionDialog = false

    created(): void {
        this.retrieveCollectionList({ archived: this.archived })
    }

    @Watch('archived')
    private onArchivedProjectsChanges(value: boolean): void {
        this.retrieveCollectionList({ archived: value })
    }

    private retrieveCollectionList(params = {}): void {
        collectionService.getCollectionList(params).then(
            (response: any) => {
                this.collectionList = response.body.content
            },
            (error: any) => {
                console.error(error)
            }
        )
    }

    createCollection(collectionForm: CollectionPost): void {
        this.collectionDialog = false
        collectionService.createCollection(collectionForm).then(
            (response: any) => {
                this.$router.push({ name: 'collection-detail', params: { id: response.body.id } })
            },
            (error: any) => {
                console.error(error)
            }
        )
    }

    toggleArchivedProject(): void {
        this.$router.replace({ query: { archived: (!this.archived).toString() } })
    }
}
