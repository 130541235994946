









































































































































































































































import { EventModel, EventPostOrPatch } from '@/models/event.model'
import { dateFormat } from '@/pipes'
import { isEventRelatedToDate } from '@/utils/event.utils'
import moment from 'moment'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component
export default class EventDialog extends Vue {
    @Prop({ required: true }) isDialogOpen!: boolean
    @Prop({ required: false }) event?: EventModel
    @Prop({ required: false }) relatedToDate?: string
    @Prop({ required: false }) startDatePlaceholder?: string

    confirmDelete = false
    eventForm = {
        valid: false,
        data: {
            name: '',
            description: '',
            startDate: '',
            startTime: '',
            endDate: '',
            endTime: '',
            takesWholeDay: false,
        },
        rules: {
            name: [
                (value: string) => !!value || 'Event name is required',
                (value: string) => value.length <= 50 || 'Max 50 characters',
            ],
            description: [(value: string) => value.length <= 150 || 'Max 150 characters'],
            startDate: [(value: string) => !!value || 'Start date is required'],
        },
    }

    dateError = false
    startTimeError = false
    endTimeError = false
    relatedToDateError = false

    startDatePicker = false
    startTimePicker = false
    endDatePicker = false
    endTimePicker = false

    get form(): Vue & { resetValidation: () => void } {
        return this.$refs.form as Vue & { resetValidation: () => void }
    }

    get inputName(): Vue & { focus: () => void } {
        return this.$refs.name as Vue & { focus: () => void }
    }

    get formattedDate(): (value: string) => string {
        return date => (date ? moment(date).format('D MMM. Y') : '')
    }

    get isPickerOpen(): boolean {
        return (
            this.startDatePicker || this.startTimePicker || this.endDatePicker || this.endTimePicker
        )
    }

    get showDateError(): boolean {
        return this.dateError && !this.isPickerOpen
    }

    get showStartTimeError(): boolean {
        return this.startTimeError && !this.isPickerOpen
    }

    get showEndTimeError(): boolean {
        return this.endTimeError && !this.isPickerOpen
    }

    get dateErrorMessage(): string | null {
        return this.showDateError ? 'End date must be set after the start date' : null
    }

    get startTimeErrorMessage(): string | null {
        return this.showStartTimeError
            ? 'Start time must be provided for event that occur within a day'
            : null
    }

    get endTimeErrorMessage(): string | null {
        return this.showEndTimeError
            ? 'End time must be provided for event that occur within a day'
            : null
    }

    beforeMount(): void {
        this.populateForm(this.event)
    }

    @Watch('isDialogOpen')
    private onIsDialogOpenChanges(value: boolean): void {
        if (!value) return

        this.confirmDelete = false
        this.form.resetValidation()
        this.populateForm(this.event)
        if (!this.event) this.inputName.focus()
    }

    @Watch('eventForm.data', { deep: true })
    private onEventFormChanges(): void {
        const { startDate, endDate, takesWholeDay } = this.eventForm.data

        if (takesWholeDay) {
            this.resetStartTime()
            this.resetEndDate()
        }
        if (startDate && endDate) this.validateDate()
        if (this.relatedToDate) {
            const tempEvent = <EventModel>{ startDate, endDate }
            this.relatedToDateError = !isEventRelatedToDate(tempEvent, this.relatedToDate)
        }
    }

    private validateDate(): void {
        const { data } = this.eventForm

        const startDate = moment(`${data.startDate}T${data.startTime ? data.startTime : '00:00'}`)
        const endDate = moment(`${data.endDate}T${data.endTime ? data.endTime : '00:00'}`)

        this.dateError = endDate.isSameOrBefore(startDate)
        this.startTimeError = data.startDate === data.endDate && !data.startTime
        this.endTimeError = data.startDate === data.endDate && !data.endTime
    }

    private populateForm(event?: EventModel): void {
        if (event) {
            this.eventForm.data = {
                name: event.name,
                description: event.description ?? '',
                startDate: event.startDate,
                startTime: event.startTime ?? '',
                endDate: event.endDate ?? '',
                endTime: event.endTime ?? '',
                takesWholeDay: event.takesWholeDay,
            }
        } else {
            const startDate = this.startDatePlaceholder
                ? moment(this.startDatePlaceholder)
                : moment()

            this.eventForm.data = {
                name: '',
                description: '',
                startDate: startDate.format('YYYY-MM-DD'),
                startTime: '',
                endDate: '',
                endTime: '',
                takesWholeDay: false,
            }
        }
    }

    emitSubmitEvent(): void {
        if (!this.eventForm.valid || this.relatedToDateError) return

        const { data } = this.eventForm
        const event: EventPostOrPatch = {
            name: data.name,
            description: data.description || null,
            startDate: data.startDate,
            startTime: data.startTime || null,
            endDate: data.endDate || null,
            endTime: data.endTime || null,
            takesWholeDay: data.takesWholeDay,
        }

        this.$emit('submit', event)
        if (this.event) this.$emit('update', { id: this.event.id, data: event })
        else this.$emit('create', event)
    }

    emitDeleteEvent(): void {
        if (!this.confirmDelete) {
            this.confirmDelete = true
            return
        }

        if (this.event) this.$emit('delete', this.event.id)
    }

    emitCloseEvent(): void {
        this.$emit('close')
    }

    resetStartTime(): void {
        this.eventForm.data.startTime = ''
        this.startTimeError = false
    }

    resetEndDate(): void {
        this.eventForm.data.endDate = ''
        this.eventForm.data.endTime = ''
        this.dateError = false
        this.startTimeError = false
        this.endTimeError = false
    }

    allowedMinutes(value: number): boolean {
        return value % 5 === 0
    }

    dateFormat(date: string, format: string): string {
        return dateFormat(date, format)
    }
}
