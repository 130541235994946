








import { Component, Prop, VModel, Vue } from 'vue-property-decorator'

@Component
export default class FilterChip extends Vue {
    @VModel() active!: boolean
    @Prop({ required: true }) color!: string
    @Prop({ required: true }) icon!: string
}
