




































import { Component, Vue } from 'vue-property-decorator'
import { User, UserChangeEmail } from '@/models/user.model'
import { userApi } from '@/api'
import { Form } from '@/models/common.model'
import ConfirmPasswordDialog from '@/components/ConfirmPasswordDialog.vue'
import TertiaryTitle from '@/components/TertiaryTitle.vue'

@Component({
    components: { TertiaryTitle, ConfirmPasswordDialog },
})
export default class ProfileEmail extends Vue {
    form: Form<UserChangeEmail> = {
        valid: false,
        pending: false,
        data: {
            email: '',
        },
        rules: {
            email: [
                (value: string) => value.length <= 100 || 'Max 100 characters',
                (value: string) =>
                    value.length === 0 ||
                    /^[\w-\.]+@([\w-]+\.)+[\w-]{2,8}$/.test(value) ||
                    'Invalid e-mail address',
            ],
        },
    }

    emailUniqueError: string | null = null
    private emailValidationTimer?: number = undefined

    confirmPasswordDialog = false

    get user(): User {
        return this.$store.state.user.user
    }

    get formRef(): Vue & { validate: () => boolean; resetValidation: () => void } {
        return this.$refs.form as Vue & { validate: () => boolean; resetValidation: () => void }
    }

    validateEmail(value: string): void {
        clearTimeout(this.emailValidationTimer)
        if (value === '') {
            this.emailUniqueError = null
            return
        }

        this.form.pending = true
        this.emailValidationTimer = setTimeout(() => this.isEmailUnique(value), 300)
    }

    private isEmailUnique(value: string): void {
        userApi
            .isEmailUnique({ email: value })
            .then((response: any) => {
                this.emailUniqueError = !response.body.unique ? 'This email is already used' : null
            })
            .catch((error: any) => console.error(error))
            .finally(() => (this.form.pending = false))
    }

    submit(): void {
        if (!this.formRef.validate() || !this.form.data.email) return

        this.confirmPasswordDialog = true
    }

    changeEmail(): void {
        userApi
            .changeEmail(this.form.data)
            .then(() => {
                this.form.data.email = ''
                this.formRef.resetValidation()
            })
            .catch((error: any) => console.error(error))
    }
}
