









import { Component, Prop, Vue } from 'vue-property-decorator'

// Todo : change path of svg
@Component
export default class EmptyListDisplay extends Vue {
    @Prop() message!: string
}
