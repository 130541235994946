




















import { Component, Prop, Vue } from 'vue-property-decorator'
import { authApi } from '@/api'
import { authService } from '@/services'

@Component
export default class ActivateUser extends Vue {
    @Prop({ default: '', required: true }) uidb64!: string
    @Prop({ default: '', required: true }) token!: string

    state: 'tokenInvalid' | 'mailSent' | null = null

    created() {
        authApi
            .activateUser({ uidb64: this.uidb64, token: this.token })
            .then(() => {
                // TODO : add alert
                if (authService.isAuthenticated()) {
                    authService.removeToken()
                    authService.resetStore()
                }
                this.$router.push({ name: 'login' })
            })
            .catch(() => {
                this.state = 'tokenInvalid'
            })
    }

    resendEmail(): void {
        authApi.resendActivationEmail({ uidb64: this.uidb64 }).then((this.state = 'mailSent'))
    }
}
