




























import { Component, Prop, Vue } from 'vue-property-decorator'
import { EventModel } from '@/models/event.model'
import moment from 'moment/moment'
import { isPassed } from '@/utils/event.utils'
import EventItemCard from '@/views/components/event/EventItemCard.vue'

@Component({ components: { EventItemCard } })
export default class DailyDetailEventTimeline extends Vue {
    @Prop({ required: true }) date!: string
    @Prop({ required: true }) events!: EventModel[]

    get isToday(): boolean {
        return moment().isSame(this.date, 'day')
    }

    get eventText(): string {
        if (this.isToday)
            return `You have ${this.events.length} ${
                this.events.length > 1 ? 'events' : 'event'
            } today !`
        else
            return `You had ${this.events.length} ${
                this.events.length > 1 ? 'events' : 'event'
            } that day !`
    }

    isEventPassed(event: EventModel): boolean {
        return isPassed(event)
    }
}
