









import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class TaskCounter extends Vue {
    @Prop({ required: true }) value!: number
    @Prop({ required: true }) max!: number
    @Prop({ required: false, default: true }) showIcon!: boolean
}
